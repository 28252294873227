import React from 'react';
import './NumberFlipper.css'

const NumberFlipper = (props) => {
  let numArray = props.units ? [props.units] : []
  // eslint-disable-next-line
  numArray = numArray.concat(props.number.toString().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").split('').map(Number))
  let divArray = numArray.map((num,i)=>(
    <div key={i} className='Number' style={{animationDelay:`${i*.1}s`}}>
      {num}
      <div className='Flipper' style={{animationDelay:`${i*.1}s`}}/>
    </div>
  ))
  if (divArray.length > 6){
    divArray.splice(divArray.length-5, 0, <div key={-1} className='Punctuation'>,</div>);
  }
  if (divArray.length > 3){
    divArray.splice(divArray.length-2, 0, <div key={-2} className='Punctuation'>.</div>);
  }
  let scale = props.scale ? {transform:`scale(${props.scale})`,transformOrigin:'top right'} : null
  return (
    <div className='NumberFlipper' style={{WebkitTextFillColor: props.color, color: props.color, ...scale}}>
      {divArray}
    </div>
  )
}

export default NumberFlipper;