import React from "react"
import { withMobile } from "hoc"

const HOURS = [
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm",
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
]

const DAY_WIDTH = 30 // this is a sass variable in hours.scss. Keep this in sync
// with $day_width

class HoursBarChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hours: this.props.hours,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.hours) !== JSON.stringify(this.props.hours)) {
      this.setState({ hours: this.props.hours })
    }
  }

  render() {
    let that = this
    return (
      !this.props.isMobile && (
        <div className="flex-col flex-align-start">
          {/* the header row */}
          <div className="bar-chart-hour-header-row">
            <div className="bar-chart-day"></div>
            {HOURS.map((hour) => (
              <div className="bar-chart-hour-header">{hour}</div>
            ))}
            <div className="bar-chart-hour-header no-right-border">5am</div>
          </div>

          {/* looping over all the days */}
          {this.props.labels.map((day, idx) => (
            <div className="bar-chart-row">
              <div className="bar-chart-day">{day}</div>
              <div className="bar-chart-hours">
                {/* draw the hours bars */}
                {/* add one to index since we start days at 1 not 0 */}
                {that.state.hours[idx].map((hours) => {
                  // set left to open / 1440 * day_width
                  // set width to (close - open) / 1440 * day_width
                  let left = (hours.open_at_min / 1440) * DAY_WIDTH * 24
                  let width =
                    ((hours.close_at_min - hours.open_at_min) / 1440) *
                    DAY_WIDTH *
                    24
                  let style = { width: width, left: left + "px" }

                  //round close at min to the nearest multiple of 15 
                  let close = hours.close_at_min // + (15-hours.close_at_min % 15)
                  if(close % 15 !== 0){
                    close = close + 15 - (hours.close_at_min % 15)
                  }
                  let numHours = (close - hours.open_at_min) / 60
                  // fix weirdness around :59pm
                  if (numHours - Math.floor(numHours) > 0.95) {
                    numHours = Math.ceil(numHours)
                  }

                  return (
                    <div style={style} className="bar-chart-bar">
                      <div className="bar-chart-bar-duration">
                        {numHours} hours
                      </div>
                    </div>
                  )
                })}
                {HOURS.map((hour) => (
                  <div className="bar-chart-hour"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )
    )
  }
}
export default withMobile(HoursBarChart)
