import React from "react"
import "./sandbox.scss"
import { useHistory } from "react-router-dom"

const Sandbox = () => {
  const history = useHistory()
  if (window.location.hostname !== "localhost") {
    history.push("/")
  }
  return <div>sldjknasdasd</div>
}

export default Sandbox
