import React, { useCallback } from "react"
import MenuItemsSearch from "./MenuItemsSearch"
import { withMobile } from "hoc"
import { Text, ButtonWrapper } from "components"

const BUFFER_AMT = 30

const Sections = (props) => {
  const {
    sections,
    activeSectionId,
    updateSection,
    DeactivationButton,
    deactivatedItems,
    isMobile,
  } = props
  let containerRef = useCallback((node) => {
    if (node) {
      const box = node.getBoundingClientRect()
      if (box.height > window.innerHeight) {
        const diff = box.height - window.innerHeight
        node.style.top = `-${diff + BUFFER_AMT}px`
      }
    }
  })

  const DEACTIVATED_NAME = props.isOnModifiers
    ? "Deactivated Modifiers"
    : "Deactivated Items"

  return (
    <div className="menu-categories no-wrap" ref={containerRef}>
      {DeactivationButton()}
      <ButtonWrapper
        onClick={() => updateSection(null)}
        className={!isMobile && "mt-8"}
      >
        <Text size="xl" weight="black">
          {props.categoryHeader}
        </Text>
      </ButtonWrapper>
      {sections.map((section, i) => {
        const isArray = Array.isArray(props.menu[section])
        const items = isArray ? props.menu[section] : props.menu[section].items
        return (
          <ButtonWrapper
            onClick={() => updateSection(section)}
            key={i}
            className="block mt-4 pl-0"
          >
            <Text
              color="black"
              style={{fontSize:'20px'}}
              weight={section === activeSectionId ? "black" : "regular"}
            >
              {section} ({items.length})
            </Text>
          </ButtonWrapper>
        )
      })}
      <ButtonWrapper
        onClick={() => updateSection(DEACTIVATED_NAME)}
        key={-1}
        className="block mt-8 pl-0"
      >
        <Text
          color="pink"
          weight={DEACTIVATED_NAME === activeSectionId ? "black" : "regular"}
        >
          {DEACTIVATED_NAME} ({deactivatedItems.length})
        </Text>
      </ButtonWrapper>
    </div>
  )
}

export default withMobile(Sections)
