export const normalizeImageUrl = ({ url, size }) => {
  if (!url) {
    return null
  }

  let newSrc = url.split(".")

  //Iterate later to determine a better way to split and replace cdn url
  if (newSrc[0] !== "https://cdn") {
    return url
  }
  newSrc[0] = "https://imgcdn"
  newSrc[
    newSrc.length - 1
  ] += `?force_jpeg=1&size=${size.w}x${size.h}`
  newSrc = newSrc.join(".")
  return newSrc


}

export const pluralize = (str, len, additionalChars = "") => {
  return len > 1 ? `${str + additionalChars}s` : str
}

export { formatPrice } from "./formatPrice"

export const deepCopyFunction = (inObject) => {
  let outObject, value, key

  if (typeof inObject === "")
    if (typeof inObject !== "object" || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value)
  }

  return outObject
}

export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
