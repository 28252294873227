import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Text } from "components"
import "./button.scss"

const sizes = ["s", "sm", "m", "l", "xl"]
const colors = ["blue", "pink", "black"]
const types = ["primary", "primary-m", "secondary", "primary-inverted"]

const typeSizes = {
  primary: "l",
  "primary-inverted": "l",
  "primary-m": "m",
}

const Button = ({
  as,
  children,
  size,
  fontSize,
  weight,
  textColor,
  color,
  type,
  noRadius,
  transparent,
  disabled,
  onClick,
  className,
}) => {
  const WrapperElement = as ? as : "button"
  const isPrimary = type === "primary" || type === "primary-inverted"
  // const tc = isPrimary && !textColor ? "white" : textColor
  return (
    <WrapperElement
      onClick={disabled ? () => {} : onClick}
      className={classnames("a-button", {
        [`button--${size}`]: size,
        [`button--${type}`]: type,
        [`button--${color}`]: color,
        "ph-7 pv-3": isPrimary,
        "button--no-radius": noRadius,
        [`button--transparent`]: transparent,
        "button--disabled": disabled,
        [className]: className,
      })}
    >
      <Text
        weight={weight}
        color={textColor}
        size={isPrimary && !fontSize ? typeSizes[type] : fontSize}
        align="center"
      >
        {children}
      </Text>
    </WrapperElement>
  )
}

Button.defaultProps = {
  size: "m",
  color: "blue",
  onClick: () => {},
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  noRadius: PropTypes.bool,
  type: PropTypes.oneOf(types),
  transparent: PropTypes.bool,
  as: PropTypes.elementType,
  size: PropTypes.oneOf(sizes),
  fontSize: PropTypes.oneOf(sizes),
  color: PropTypes.oneOf(colors),
}

export default Button
