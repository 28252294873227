import React, { Component } from "react"
import Sidebar from "./Sidebar/Sidebar"
import { withRouter } from "react-router-dom"
import "./App.css"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rest: null,
      bg: "#dee8f3",
      page: "feed",
    }
  }

  componentDidMount() {
    this.setInitialPage()
    this.setState({ mobile: window.innerWidth < 800 })
    window.addEventListener("resize", this.resizeWindow.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeWindow)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setInitialPage()
    }
  }

  // what the fuck, someone deprecate this (tom)
  shouldBeWhite(path) {
    return path === "menus" || path === "qr" || path === "hours" || path === "profile" || path === 'settings'
  }

  setInitialPage() {
    const path = this.props.location.pathname.split("/")[1]
    let bg = this.shouldBeWhite(path.toLowerCase()) ? "white" : "#dee8f3"
    this.setState({ page: path, bg })
  }

  resizeWindow() {
    const windowState = window.innerWidth < 800

    if (windowState !== this.state.mobile) {
      this.setState({ mobile: windowState })
    }
  }

  render() {
    return (
      <div className="App" style={{ backgroundColor: this.state.bg }}>
        <Sidebar
          rest={this.props.rest}
          user={this.props.user}
          brand={this.props.brand}
          page={this.state.page}
          mobile={this.state.mobile}
          refreshData={() => this.props.refreshData()}
        />
        <div
          className={this.state.mobile ? "AppBodyMobile" : "AppBody"}
          id="AppBody"
        >
          <div className=" fluid-content--body">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default withRouter(App)
