import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./Footer.css"

let pages = ["feed", "integrations", "finance", "support"]

let icons = ["/feed.png", "/integrations.png", "/finance.png", "/support.png"]
class Footer extends Component {
  renderIcons() {
    let active = this.props.page

    if (!this.props.rest.online_ordering_enabled)
      pages = ["feed", "integrations", "support"]

    if (!this.props.rest.online_ordering_enabled)
      icons = ["/feed.png", "/integrations.png", "/support.png"]
    return pages.map((page, i) => {
      return (
        <Link
          key={i}
          to={`/${page}`}
          className={
            page === active
              ? "SidebarPageMobile SidebarPageActive"
              : "SidebarPageMobile"
          }
        >
          <img src={icons[i]} className="SidebarIcon" alt="menu item" />
          <div className="cfff fs10 fw700">{page.toUpperCase()}</div>
        </Link>
      )
    })
  }

  render() {
    return <div className="Footer">{this.renderIcons()}</div>
  }
}

export default Footer
