import React, { useState, useEffect } from "react"
import classnames from "classnames"
import {
  Text,
  ButtonWrapper,
  Button,
  DragAndDrop,
  SaveActions,
} from "components"
import { ReactComponent as PencilEdit } from "assets/icons/pencil.svg"
import { ReactComponent as ImagePlaceholder } from "assets/icons/image-placeholder.svg"
import { useToasts } from "toasts"
import Modal from "Modal/Modal"

const Logo = (props) => {
  let fileRef = React.useRef(null)
  const toasts = useToasts()

  const [isModalOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState(null)
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (file) {
      console.log(file.type !== "image/png")
      if (file.type !== "image/png" && file.type !== "image/jpg") {
        toasts.add({
          message: "Please choose a PNG or JPG",
          type: "error",
        })

        setFile(null)
        return
      }
      try {
        const objectURL = window.URL.createObjectURL(file)
        setImage(objectURL)
      } catch (e) {
        try {
          // Fallback if createObjectURL is not supported
          const fileReader = new FileReader()
          fileReader.onload = (evt) => {
            setImage(evt.target.result)
          }
          fileReader.readAsDataURL(file)
        } catch (e) {
          console.log(`File Upload not supported: ${e}`)
        }
      }
    }
  }, [file])

  const handleImageDrop = (e) => {
    let files = [...e.dataTransfer.files]
    if (!files) {
      return
    }
    const image = files[0]
    console.log(image)
    setFile(image)
  }

  const handleUpload = (e) => {
    const image = e.target.files[0]
    console.log(image)
    if (!image) {
      toasts.add({
        message: "Image upload unsuccessful",
        type: "error",
      })
    }

    setFile(image)
  }

  const handleClick = () => {
    setIsOpen(true)
  }

  const hasImage = () => !!props.image
  const closeModal = () => setIsOpen(false)

  const deleteImage = () => {
    setFile(null)
    setImage(null)
  }

  const onSave = () => {
    /*
    scott this is where I need you to save the image to s3 etc etc

    The file is in the state variable: file

    it looks like this:
    lastModified: 1589381067854
    lastModifiedDate: Wed May 13 2020 09:44:27 GMT-0500 (Central Daylight Time) {}
    name: "delivery.png"
    size: 181033
    type: "image/png"
    webkitRelativePath: ""
    */

    //refresh the data
    props.refreshData()

    //close modal
    setIsOpen(false)
  }

  return (
    <div className="flex-col">
      <div className="flex-row flex-apart flex-align-start">
        <Text size="l" weight="black">
          {props.header}:
        </Text>
        <ButtonWrapper onClick={handleClick}>
          <PencilEdit height={22} width={22} fill="#D62E70" />
        </ButtonWrapper>
      </div>
      <Text size="xs" className="logo__used-in mt-2">
        {`Used in: ${props.usedIn}`}
      </Text>
      <div
        className={classnames("logo__image-container", {
          "logo__image-container--with-image": hasImage(),
        })}
      >
        {hasImage() ? (
          <img src={props.image} alt={props.header} className="logo__image" />
        ) : (
          <Button type="primary" color="pink" onClick={handleClick}>
            Upload Image
          </Button>
        )}
        <input
          type="file"
          hidden
          ref={(el) => (fileRef = el)}
          onChange={handleUpload}
        />
      </div>
      {isModalOpen && (
        <Modal close={closeModal}>
          <LogoEditModal
            onSave={onSave}
            deleteImage={deleteImage}
            handleImageDrop={handleImageDrop}
            handleUploadClick={() => fileRef.click()}
            onCancel={closeModal}
            image={image}
          />
        </Modal>
      )}
    </div>
  )
}

class LogoEditModal extends React.Component {
  state = {
    isDraggingOver: false,
  }
  isUploaded = () => !!this.props.image

  render() {
    const disabled = !this.isUploaded()
    return (
      <div className="ph-8 pt-8 pb-6">
        <Text weight="black" size="l">
          Upload Logo
        </Text>
        {this.isUploaded() ? (
          <div className="logo__image-uploaded">
            <img src={this.props.image} alt="logo" />
          </div>
        ) : (
          <DragAndDrop
            handleDrop={(e) => this.props.handleImageDrop(e)}
            handleDragOver={() => this.setState({ isDraggingOver: true })}
            handleDragLeave={() => this.setState({ isDraggingOver: false })}
          >
            <ButtonWrapper
              className={classnames("logo__upload-container", {
                "logo__upload-container--dragging": this.state.isDraggingOver,
              })}
              onClick={this.props.handleUploadClick}
            >
              <ImagePlaceholder />
              <Text color="blue" className="mt-4">
                Drop your image here, or <u>upload a file</u>
              </Text>
            </ButtonWrapper>
          </DragAndDrop>
        )}
        <div className="mv-8 inline-block">
          {this.isUploaded() && (
            <ButtonWrapper onClick={this.props.deleteImage}>
              <Text color="pink" weight="bold">
                <u>Delete Image</u>
              </Text>
            </ButtonWrapper>
          )}
        </div>

        <div className="logo__edit-modal-actions">
          <Text size="s" color="grey" className="mr-2">
            Please upload 4:3 or 16:9 in PNG format for best results
          </Text>
          <SaveActions
            onCancel={this.props.onCancel}
            onSave={this.props.onSave}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
}

export default Logo
