import React, { Component } from "react"
import "./Support.css"

class Support extends Component {
  render() {
    if (this.props.user) {
      return (
        <div className="Support">
          <img className="w40" src="/taco.png" alt="footer" />
          <h1 className="m0">Support</h1>
          <div className="pv8 fs12 fw700 c222 inline-block w100">
            Got a problem, an article, or an idea that you want us to know
            about? Reach out to the HNGR team here.
          </div>

          <form action="https://formspree.io/xyyneazg" method="POST">
            <input type="hidden" name="name" value={this.props.user.name} />
            <input
              type="hidden"
              name="_replyto"
              value={this.props.user.email}
            />
            <textarea
              className="mv16"
              rows={3}
              placeholder="Your message..."
              name="messsage"
            />
            <br />
            <button className="button" type="submit">
              SUBMIT
            </button>
          </form>
        </div>
      )
    } else return null
  }
}

export default Support
