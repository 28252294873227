import React, { Component } from "react"
import Card from "../Card"
import Modal from "../../Modal/Modal"
import "./NewsCard.css"

class NewsCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  formatDatetime(datetime) {
    let date = new Date(datetime)
    return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
  }

  renderExpansion() {
    if (this.state.expanded) {
      console.log(this.props.story.url, this.props.story.amp_url)
      return (
        <Modal
          close={() => this.setState({ expanded: false })}
          style={{ height: "80%", padding: "8px" }}
        >
          <iframe
            title="article"
            src={
              this.props.story.url.amp_url
                ? this.props.story.url.amp_url
                : this.props.story.url
            }
          />
          <a
            href={this.props.story.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button iFrameButton">OPEN IN NEW TAB</button>
          </a>
        </Modal>
      )
    }
  }

  render() {
    return (
      <div id="NewsCard">
        <a
          href={this.props.story.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card>
            <img
              src={this.props.story.img_url}
              className="NewsCardImg"
              alt="new article"
            />
            <div style={{ padding: "4px 16px 16px 16px" }}>
              <div className="fs24 fw900 c222 left">
                {this.props.story.title}
              </div>
              <div className="fs16 left" style={{ paddingBottom: "4px" }}>
                <span className="fw700 cblue">{this.props.story.source}</span>
                <span className="fw300 c222">
                  {" "}
                  - {this.formatDatetime(this.props.story.created_at)}
                </span>
              </div>
              <div className="fs16 fw500 c222 left">
                {this.props.story.subtitle}
              </div>
            </div>
          </Card>
        </a>
        {this.renderExpansion()}
      </div>
    )
  }
}

export default NewsCard
