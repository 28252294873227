import React, { Component } from 'react';
import Card from '../Card'
import Modal from '../../Modal/Modal'
import ReactHtmlParser from 'react-html-parser';
import './PostCard.css';

class PostCard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  formatDatetime(datetime){
    let date = new Date(datetime)
    return (date.getMonth()+1) + '/' + date.getDate() + '/' +  date.getFullYear()
  }

  renderExpansion(){
    if (this.state.expanded){
      return(
        <Modal close={()=>this.setState({expanded:false})}>
          <img src='/hngr_header.png' className='PostCardImg' alt='hngr logo'/>
          <div style={{padding: '4px 16px 16px 16px'}}>
            <div className='fs24 fw900 c222 left'>{this.props.story.title}</div>
            <div className='fs16 left fw300 c222' style={{paddingBottom:'4px'}}>{this.formatDatetime(this.props.story.created_at)}</div>
            <div className='fs21 fw500 c222 left' style={{width:'100%'}}>{ReactHtmlParser(this.props.story.content)}</div>
          </div>
        </Modal>
      )
    }
  }

  render(){
    return (
      <div id='PostCard'>
        <Card onClick={()=>this.setState({expanded:true})}>
          <img src='/hngr_header.png' className='PostCardImg' alt='hngr logo'/>
          <div style={{padding: '4px 16px 16px 16px'}}>
            <div className='fs24 fw900 c222 left'>{this.props.story.title}</div>
            <div className='fs16 left fw300 c222' style={{paddingBottom:'4px'}}>{this.formatDatetime(this.props.story.created_at)}</div>
            <div className='fs21 fw500 c222 left' style={{width:'100%'}}>{ReactHtmlParser(this.props.story.content)}</div>
          </div>
        </Card>
        {this.renderExpansion()}
      </div>
    )
  }
}

export default PostCard;
