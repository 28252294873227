import React, { Component } from "react"
import Card from "../Card"
import Modal from "../../Modal/Modal"
import { Link } from "react-router-dom"
import Loading from "../../Loading/Loading"
import NumberFlipper from "../../NumberFlipper/NumberFlipper"
import { Bar } from "react-chartjs-2"
import "./Grubcard.css"

const API = require("../../api")

class Grubcard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      start_date: null,
      end_date: null,
      sales: null,
      fees: null,
      expanded: false,
    }
  }

  componentDidMount() {
    if (this.props.rest) {
      this.getInsights()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rest.name !== this.props.rest.name) {
      this.getInsights()
    }
  }

  getInsights() {
    API.call(
      "grubhub",
      (res) => this.handleAPISuccess(res),
      (err) => this.handleAPIFailure(err),
      { rest_id: this.props.rest.restaurant_id },
      null
    )
  }

  handleAPISuccess(res) {
    this.setState({
      loading: false,
      dates: res.dates,
      sales: res.sales,
      fees: res.fees,
    })
  }

  handleAPIFailure(err) {
    console.log("FAILURE:", err)
    this.setState({ loading: false })
  }

  expandCard() {
    this.setState({ expanded: true })
  }

  aggregate(array) {
    let sum = 0
    for (let item in array) {
      sum += array[item]
    }
    return Math.round(sum * 100) / 100
  }

  renderExpansion() {
    if (this.state.expanded) {
      console.log(this.state.sales)
      return (
        <Modal
          close={() => this.setState({ expanded: false })}
          style={{
            maxWidth: "64%",
            paddingBottom: "24px",
            background: this.props.rest.hex_color_secondary
              ? this.props.rest.hex_color_secondary
              : "#4284f4",
          }}
        >
          <div className="GrubcardHeader">
            <img className="GrubcardLogo" src="/GrubHub.png" alt="logo" />
            <div className="fs28 fw900 cfff left mv8 textshadow">
              Your Grubhub Data this Month
            </div>
          </div>

          <div
            className="center inline-block w50 vamiddle"
            style={{ minWidth: "300px" }}
          >
            <div className="fw900 fs16 pv16 cfff textshadow">
              <img
                src="/calendar.png"
                className="GrubcardCalendarImg"
                alt="calendar"
              />
              {this.state.dates[0].split(" -")[0] +
                " - " +
                this.state.dates[3].split(" -")[1]}
            </div>
            <div className="cgreen">
              <NumberFlipper
                number={this.state.sales[3].toFixed(2)}
                color="#34a853"
                units="$"
              />
            </div>
            <div className="GrubcardCaption cfff fw700 fs12 textshadow">
              TOTAL FOOD & BEVERAGE SALES
            </div>
            <br />
            <div className="cred">
              <NumberFlipper
                number={this.state.fees[3].toFixed(2)}
                color="#ea4335"
                units="$"
              />
            </div>
            <div className="GrubcardCaption cfff fw700 fs12 textshadow">
              TOTAL GRUBHUB FEES
            </div>
          </div>

          <div
            className="center inline-block w50 vamiddle"
            style={{ minWidth: "300px" }}
          >
            <Bar
              data={{
                labels: this.state.dates,
                datasets: [
                  {
                    type: "line",
                    data: this.state.sales,
                    fill: true,
                    backgroundColor: "rgba(220,220,220,0)",
                    borderWidth: 2,
                    borderColor: "#4284f4",
                    pointBorderColor: "#4284f4aa",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: "#4284f4",
                    pointHoverBorderWidth: 2,
                  },
                  {
                    data: this.state.sales,
                    fill: true,
                    backgroundColor: "rgba(255,255,255,.4)",
                    borderWidth: 2,
                    borderColor: "rgba(255,255,255,.8",
                    pointBorderColor: "rgba(220,220,220,1)",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgba(220,220,220,1)",
                    pointHoverBorderColor: "rgba(220,220,220,1)",
                    pointHoverBorderWidth: 2,
                  },
                ],
              }}
              options={{
                legend: {
                  display: false,
                },
                scaleFontColor: "red",
                responsive: true,
                tooltips: {
                  mode: "single",
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        fontColor: "#fff",
                      },
                    },
                  ],
                  yAxes: [
                    {
                      // display: false,
                      beginAtZero: true,
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        beginAtZero: true,
                        fontColor: "#fff",
                      },
                    },
                  ],
                },
              }}
            />
          </div>
        </Modal>
      )
    }
  }

  renderContent() {
    if (!this.props.integration) {
      return (
        <div className="center">
          <img src="/error.png" className="w96px" alt="error" />
          <div className="fw500 fs16 cfff pv8 ph16">
            Connect your Grubhub account to access more data and insights:
          </div>
          <Link to="/integrations">
            <button className="button mv16">CONNECT MY ACCOUNT</button>
          </Link>
        </div>
      )
    }
    if (this.state.loading) {
      return <Loading />
    }
    if (this.state.sales && this.state.fees) {
      return (
        <div className="center">
          <div className="fw900 fs16 pv16 cfff textshadow">
            <img
              src="/calendar.png"
              className="GrubcardCalendarImg"
              alt="calendar"
            />
            {this.state.dates[3]}
          </div>
          <div className="cgreen">
            <NumberFlipper
              number={this.state.sales[3].toFixed(2)}
              color="#34a853"
              units="$"
            />
          </div>
          <div className="GrubcardCaption cfff fw700 fs12 textshadow">
            TOTAL FOOD & BEVERAGE SALES
          </div>
          <br />
          <div className="cred">
            <NumberFlipper
              number={this.state.fees[3].toFixed(2)}
              color="#ea4335"
              units="$"
            />
          </div>
          <div className="GrubcardCaption cfff fw700 fs12 textshadow">
            TOTAL GRUBHUB FEES
          </div>
          <br />
        </div>
      )
    } else {
      return (
        <div className="center">
          <img src="/plate.png" className="w60" alt="plate icon" />
          <h2 className="ph8">Your Insights are On The Way!</h2>
          <p className="ph16">
            We're gathering data from your connected account now. Come back in
            the next few days to see your insights.
          </p>
        </div>
      )
    }
  }

  render() {
    if (this.props.rest) {
      return (
        <div id="Grubcard">
          <Card
            style={{
              background: this.props.rest.hex_color_secondary
                ? this.props.rest.hex_color_secondary
                : "#4284f4",
              cursor: "pointer",
            }}
            onClick={() => this.expandCard()}
          >
            <div className="GrubcardHeader">
              <img className="GrubcardLogo" src="/GrubHub.png" alt="logo" />
              <div className="fs28 fw900 cfff left mv8 textshadow">
                Your Grubhub Data
              </div>
            </div>
            {this.renderContent()}
          </Card>
          {this.renderExpansion()}
        </div>
      )
    } else return null
  }
}

export default Grubcard
