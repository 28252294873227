import React, { Component } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { ToastProvider } from "toasts"
import Feed from "./Feed/Feed"
import Support from "./Support/Support"
import Integrations from "./Integrations/Integrations"
import Login from "./Login/Login"
import ResetPassword from "./Login/ResetPassword"
import { Sandbox, Menu, Hours, Profile, Settings, QR, LiveOrders } from "views"
import Finance from "./Finance/Finance"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import * as Sentry from "@sentry/browser"

import "helpers.scss"
const API = require("./api")

// CLOWNTOWN IE11 POLYFILLS!!!!

// polyfill for IE missing the includes method
// which is required for some stuff (mostly sentry)
// https://mzl.la/2MnpSqD
if (!String.prototype.includes) {
  // eslint-disable-next-line
  String.prototype.includes = function (search, start) {
    // eslint-disable-next-line
    "use strict"
    if (typeof start !== "number") {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    } else {
      return this.indexOf(search, start) !== -1
    }
  }
}

if (process.env.REACT_APP_MEMBER_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_MEMBER_SENTRY_DSN,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
    ],
    environment: process.env.REACT_APP_MEMBER_SENTRY_ENVIRONMENT
  })
}


if(window.location.href.indexOf('hngr.co') > -1){
  window.document.domain = 'hngr.co'
}


class Routes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      api_key: null,
      user: null,
      rest: null,
      rests: null,
      brand: null,
      integrations: null,
      refreshData: this.refreshData.bind(this),
      loadRest: this.loadRest.bind(this),
    }
  }

  componentDidMount() {
    this.refreshData()
    if (!this.state.api_key) {
      let api_key = localStorage.getItem("api_key")
      if (!api_key && window.location.pathname !== "/login")
        window.location = "/login"
      this.setState({ api_key: api_key })
    }
    if (!this.state.user) {
      let user = localStorage.getItem("user")
      user = JSON.parse(user)
      if (!user && window.location.pathname !== "/login")
        window.location = "/login"
      this.setState({ user: user })
    }
    if (!this.state.brand) {
      let brand = localStorage.getItem("brand")
      try {
        brand = JSON.parse(brand)
      } catch(error) {}
      this.setState({brand: brand})
    }
    if (!this.state.rest) {
      let rest = localStorage.getItem("rest")
      rest = JSON.parse(rest)
      if (!rest && window.location.pathname !== "/login")
        window.location = "/login"
      this.setState({rest: rest})
    }
    if (!this.state.integrations) {
      let integrations = localStorage.getItem("integrations")
      integrations = JSON.parse(integrations)
      // not redirecting to login because some rests might not yet have integrations
      this.setState({ integrations: integrations })
    }
  }

  refreshData() {
    API.call(
      "refresh",
      (res) => this.handleRefreshSuccess(res),
      (err) => this.handleRefreshFailure(err),
      null,
      null
    )
  }

  handleRefreshSuccess(res) {
    let user = res.user
    let rest = res.rest
    let brand = res.brand
    let rests = res.rests
    let hours = res.hours
    let integrations = res.integrations
    localStorage.setItem("user", JSON.stringify(user))
    localStorage.setItem("hours", JSON.stringify(hours))
    localStorage.setItem("rest", JSON.stringify(rest))
    localStorage.setItem("rests", JSON.stringify(rests))
    localStorage.setItem("brand", JSON.stringify(brand))
    localStorage.setItem("integrations", JSON.stringify(integrations))
    this.setState({ user, rest, rests, integrations, hours })
  }

  handleRefreshFailure(err) {
    console.log(err)
  }

  loginUser(api_key = null, user, rest, brand, rests, integrations) {
    console.log("rests", rests)
    localStorage.setItem("api_key", api_key)
    localStorage.setItem("user", JSON.stringify(user))
    localStorage.setItem("rest", JSON.stringify(rest))
    localStorage.setItem("rests", JSON.stringify(rests))
    localStorage.setItem("brand", JSON.stringify(brand))
    localStorage.setItem("integrations", JSON.stringify(integrations))
    this.setState({ user, rest, rests, integrations }, () =>
      console.log(this.state)
    )
  }

  loadRest() {
    return new Promise((resolve, reject) => {
      if (this.state.rest) {
        return resolve(this.state.rest)
      } else {
        let waitForRest = () => {
          if (this.state.rest) return resolve(this.state.rest)
          setTimeout(waitForRest, 30)
        }
        waitForRest()
      }
    })
  }

  render() {
    return (
      <Switch>
        <Route exact key="login" path="/login">
          <Login {...this.state} loginUser={this.loginUser.bind(this)} />
        </Route>
        <Route exact key="resetpw" path="/login/reset">
          <ResetPassword {...this.state} />
        </Route>
        <App {...this.state}>
          <Route key="feed" exact path={["/", "/finance"]}>
            <Finance {...this.state} />
          </Route>
          <Route exact key="int" path="/integrations">
            <Integrations {...this.state} />
          </Route>
          <Route exact key="sup" path="/support">
            <Support {...this.state} />
          </Route>

          <Route exact key="profile" path="/profile">
            <Profile {...this.state} />
          </Route>

          <Route exact key="settings" path="/settings">
            <Settings {...this.state} />
          </Route>

          <Route exact key="hours" path="/hours">
            <Hours {...this.state} />
          </Route>

          <Route exact key="qr" path="/qr">
            <QR {...this.state} />
          </Route>

          <Route exact key="liveorders" path="/liveorders">
            <LiveOrders {...this.state} />
          </Route>

          <Route exact key="menus" path="/menus">
            {this.state.rest && <Menu {...this.state} />}
          </Route>

          <Route path="/sandbox" key="sandbox" component={Sandbox} exact />
        </App>
      </Switch>
    )
  }
}

export default Routes

ReactDOM.render(
  <ToastProvider>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </ToastProvider>,
  document.getElementById("root")
)
