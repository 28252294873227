import React from "react"
import classnames from "classnames"
import "./buttonwrapper.scss"
import Button from "components/button/button"

const ButtonWrapper = ({ children, onClick, options, className, ...rest }) => {
  return (
    <button
      onClick={onClick}
      className={classnames("button-wrapper__container", {
        "button-wrapper--with-border": options.withBorder,
        [className]: className,
      })}
      {...rest}
    >
      {children}
    </button>
  )
}

ButtonWrapper.defaultProps = {
  options: {},
}

export default ButtonWrapper
