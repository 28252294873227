import React from "react"
import { Tabs } from "components"
import ProfilePage from "./profilepage"
import BrandPage from "./brandpage"
import "./profile.scss"

class Profile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  tm = () => ({
    Profile: <ProfilePage {...this.props} />,
    Brand: <BrandPage {...this.props} />,
  })

  render() {
    console.log(this.props)
    return (
      <div>
        <Tabs
          tabMap={this.tm()}
          childClass="pv-6 ph-2 flex-col"
          waitFor={this.props.rest}
        />
      </div>
    )
  }
}

export default Profile
