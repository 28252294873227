import React, { useEffect } from "react"
import cx from "classnames"
import { ButtonWrapper } from "components"
import "./toast.scss"
const toastTypes = ["success", "error"]

const ToastContext = React.createContext()

const ToastContainer = (props) => <div className="toast-container" {...props} />
const Toast = ({ type, children, onDismiss }) => {
  useEffect(() => {
    let t = setTimeout(onDismiss, 2500)

    return () => clearTimeout(t)
  }, [])
  return (
    <ButtonWrapper
      onClick={onDismiss}
      className={cx("a-toast", {
        [`a-toast--${type}`]: type,
      })}
    >
      {children}
    </ButtonWrapper>
  )
}

let toastCount = 0

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([])

  const add = ({ message, type }) => {
    const id = toastCount++
    const toast = { message, type, id }
    setToasts([...toasts, toast])
  }
  const remove = (id) => {
    const newToasts = toasts.filter((t) => t.id !== id)
    setToasts(newToasts)
  }
  const onDismiss = (id) => () => remove(id)

  return (
    <ToastContext.Provider value={{ add, remove }}>
      {children}
      <ToastContainer>
        {toasts.map(({ message, type, id, ...rest }) => (
          <Toast key={id} type={type} onDismiss={onDismiss(id)} {...rest}>
            {message}
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  )
}

export const useToasts = () => React.useContext(ToastContext)

//wrapper for use with class components
export const withToasts = (WrapperComponent) => {
  return (props) => {
    const t = useToasts()
    return <WrapperComponent toasts={{ ...t }} {...props} />
  }
}
