import React, { Component } from "react"
import Loading from "../Loading/Loading"
import Modal from "../Modal/Modal"

const API = require("../api")

class Orders extends Component {
  constructor(props) {
    super(props)
    console.log("props", props)
    this.state = {
      rows: [],
      rest: {},
      apiKey: localStorage.getItem("api_key"),
    }
    window.o = this
  }

  componentDidMount() {
    if (this.props.rows) {
      let splitTips = false
      let res = this.props
      for(let i in res.rows){
        if(res.rows[i].driver_tip && res.rows[i].driver_tip !== '$0.00' || res.rows[i].restaurant_tip && res.rows[i].restaurant_tip != '$0.00') {
          splitTips = true
        }
      }
      this.setState({ rows: this.props.rows, splitTips:splitTips })
    } else {
      this.loadOrders()
    }
    this.props.loadRest().then((rest) => this.setState({ rest: rest }))
  }

  async loadOrders() {
    let rest = await this.props.loadRest()
    API.call(
      "payout-orders",
      (res) => {
        let splitTips = false
        console.log("rows", res.rows)
        for(let i in res.rows){
          if(res.rows[i].driver_tip || res.rows[i].restaurant_tip) splitTips = true
        }
        this.setState({ rows: res.rows, splitTips:splitTips })
      },
      (err) => {
        alert("Error loading transfers")
      },
      null,
      { payout_id: this.props.payout_id, rest_id: rest.restaurant_id }
    )
  }

  csvEndpoint() {
    if (this.props.payout_id) {
      return `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/member/payout/orders`
    } else if (this.props.transactions) {
      return `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/member/transactions/orders`
    } else if (this.props.summary) {
      return `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/member/summary`
    }
  }

  render() {
    if (this.state.rows.length) {
      return (
        <Modal
          close={() => this.props.closeExpansion()}
          style={
            window.innerWidth > 800
              ? { maxWidth: "64%", padding: "16px" }
              : { maxWidth: "calc(92% - 32px)", padding: "16px" }
          }
        >
          <form method="GET" action={this.csvEndpoint()}>
            <input type="hidden" name="csv" value="1" />
            {this.props.payout_id ? (
              <input
                type="hidden"
                name="payout_id"
                value={this.props.payout_id}
              />
            ) : null}
            {this.props.transactions ? (
              <div>
                <input
                  type="hidden"
                  name="rest_id"
                  value={this.props.rest_id}
                />
                <input type="hidden" name="start" value={this.props.start} />
                <input
                  type="hidden"
                  name="start_time"
                  value={this.props.start_time}
                />
                <input type="hidden" name="end" value={this.props.end} />
                <input
                  type="hidden"
                  name="end_time"
                  value={this.props.end_time}
                />
              </div>
            ) : null}
            {this.props.summary ? (
              <div>
                <input
                  type="hidden"
                  name="rest_id"
                  value={this.props.rest_id}
                />
                <input type="hidden" name="key" value={this.props.key_} />
                <input type="hidden" name="toggle" value={this.props.toggle} />
              </div>
            ) : null}

            <input
              type="hidden"
              name="rest_id"
              value={this.state.rest.restaurant_id}
            />
            <input
              type="hidden"
              name="X-HNGR-API-KEY"
              value={this.state.apiKey}
            />
            <button className="button mv8">EXPORT</button>
          </form>
          <div
            style={{ width: "100%", overflowX: "auto", paddingBottom: "32px" }}
          >
            <table>
              <thead>
                <tr>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Date
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Scheduled Date
                  </th>
                  {!this.props.summary ? (
                    <>
                      <th
                        className="left fs14 fw900"
                        style={{ verticalAlign: "bottom", lineHeight: "1" }}
                      >
                        Transaction Type
                      </th>
                      <th
                        className="left fs14 fw900"
                        style={{ verticalAlign: "bottom", lineHeight: "1" }}
                      >
                        Description
                      </th>
                    </>
                  ) : null}
                  {this.props.payout_id ? (
                    <th
                      className="left fs14 fw900"
                      style={{ verticalAlign: "bottom", lineHeight: "1" }}
                    >
                      Restaurant
                    </th>
                  ) : null}
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Order ID
                  </th>
                  {!this.props.transactions ? (
                    <th
                      className="left fs14 fw900"
                      style={{ verticalAlign: "bottom", lineHeight: "1" }}
                    >
                      Name
                    </th>
                  ) : null}
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Type
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Product
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Promo
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Subtotal
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Delivery
                  </th>
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Tax
                  </th>
                
                 {this.state.splitTips && (
                   <>
                     <th
                        className="left fs14 fw900"
                        style={{ verticalAlign: "bottom", lineHeight: "1" }}
                      >
                        Driver Tip
                      </th> 
                      <th
                        className="left fs14 fw900"
                        style={{ verticalAlign: "bottom", lineHeight: "1" }}
                      >
                        Restaurant Tip
                      </th> 
                    </>
                )}
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Tip {this.state.splitTips && <span> Total </span>}
                  </th> 
                  <th
                    className="left fs14 fw900"
                    style={{ verticalAlign: "bottom", lineHeight: "1" }}
                  >
                    Total
                  </th>
                  {this.props.summary ? (
                    <th
                      className="left fs14 fw900"
                      style={{ verticalAlign: "bottom", lineHeight: "1" }}
                    >
                      Adjustments
                    </th>
                  ) : null}
                  {!this.props.summary ? (
                    <>
                      <th
                        className="left fs14 fw900"
                        style={{ verticalAlign: "bottom", lineHeight: "1" }}
                      >
                        HNGR Fee
                      </th>
                      <th
                        className="left fs14 fw900"
                        style={{ verticalAlign: "bottom", lineHeight: "1" }}
                      >
                        CC Processing Fee
                      </th>
                    </>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {this.state.rows.map((r, i) => (
                  <tr key={i}>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.datetime}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.scheduled_date}
                    </td>
                    {!this.props.summary ? (
                      <>
                        <td
                          className="left fs12"
                          style={{ paddingLeft: "8px" }}
                        >
                          {r.type}
                        </td>
                        <td
                          className="left fs12"
                          style={{ paddingLeft: "8px" }}
                        >
                          {r.description}
                        </td>
                      </>
                    ) : null}
                    {this.props.payout_id ? (
                      <td className="left fs12" style={{ paddingLeft: "8px" }}>
                        {r.restaurant}
                      </td>
                    ) : null}
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.order_id}
                    </td>
                    {!this.props.transactions ? (
                      <td className="left fs12" style={{ paddingLeft: "8px" }}>
                        {r.diner_name}
                      </td>
                    ) : null}
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.payment_type}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.product_total}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.promo}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.subtotal}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.delivery_total}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.tax}
                    </td>
                    {this.state.splitTips && (
                      <>
                        <td className="left fs12" style={{ paddingLeft: "8px" }}>
                          {r.driver_tip}
                        </td>
                        <td className="left fs12" style={{ paddingLeft: "8px" }}>
                          {r.restaurant_tip}
                        </td>
                      </>
                    )}
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.tip}
                    </td>
                    <td className="left fs12" style={{ paddingLeft: "8px" }}>
                      {r.total}
                    </td>
                    {this.props.summary ? (
                      <td className="left fs12" style={{ paddingLeft: "8px" }}>
                        {r.adjustments}
                      </td>
                    ) : null}
                    {!this.props.summary ? (
                      <>
                        <td
                          className="left fs12"
                          style={{ paddingLeft: "8px" }}
                        >
                          {r.hngr_fee}
                        </td>
                        <td
                          className="left fs12"
                          style={{ paddingLeft: "8px" }}
                        >
                          {r.cc_processing_fee}
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )
    } else return (<Modal style={{height:'400px'}}><Loading/></Modal>)
  }
}

export default Orders
