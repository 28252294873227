import React from "react"
import api from "api"
import { Text, Button } from "components"

class QRCodeData extends React.Component {

  constructor(props) {
    super(props)
    this.state = {forward_url: props.qr.forward_url,
      qr_code_id: props.qr.qr_code_id,
      landing_slug: props.qr.landing_slug,
      landing_domain: props.qr.landing_domain,
      updateSuccess: styles.dontShow,
      uniq_uuid: props.qr.uniq_uuid}

    this.saveForward = this.saveForward.bind(this)
    this.changeURL = this.changeURL.bind(this)
    this.onSaveSuccess = this.onSaveSuccess.bind(this)
    this.onSaveFailure = this.onSaveFailure.bind(this)
    this.deleteSelf = this.deleteSelf.bind(this)
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this)
  }

  onSaveSuccess(r) {
    this.setState({updateSuccess: styles.showSuccess,
      forward_url: r.forward_url})
  }

  onSaveFailure() {
    alert("Error saving URL")
  }

  onDeleteSuccess() {
    window.location.reload(false)
  }

  deleteSelf() {
    let payload = {'uniq_uuid': this.state.uniq_uuid}
    api.call('qr_delete', this.onDeleteSuccess,
      ()=>{}, {}, payload)
  }

  saveForward(e) {
    let uniq_uuid = this.state.uniq_uuid
    let payload = {'uniq_uuid': uniq_uuid,
      'forward_url': this.state.forward_url}
    api.call('qr_update_forward', this.onSaveSuccess,
      this.onSaveFailure, {}, payload)
  }

  changeURL(e) {
    this.setState({forward_url: e.target.value})
  }

  render() {
    return (<div>
      <Text size='l' 
        weight='bold' 
        color='pink'>https://{this.state.landing_domain}/{this.state.landing_slug}</Text>
      <hr />
      Points to: <input style={styles.inputForm} onChange={this.changeURL}
        value={this.state.forward_url} /> <br />
        <Button
          onClick={this.saveForward}
          type="primary"
          color="pink"
          size="l">
        Save
        </Button>
        <span style={this.state.updateSuccess}>Forward URL Updated</span>
        <hr />
        <a href={this.props.getQRImageLocation(this.state.qr_code_id, true)}>Download High Resolution</a> | <a onClick={this.deleteSelf} href='#' style={styles.del}>Delete</a>
      </div>)
    }
}

const styles = {
  del: {
    color: 'red'
  },
  inputForm: {
    border: '1px solid black'
  },
  hits: {
    fontFamily: 'monospace'
  },
  changeLink: {
    color: 'blue',
  },
  dontShow: {
    display: 'none',
  },
  showSuccess: {
    display: 'block',
    border: '1px solid green',
    marginTop: '10px',
    padding: '4px',
    background: 'light green'
  },
  qrImg: {
    height: '200px',
    width: '200px'
  }
}

export default QRCodeData
