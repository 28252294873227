import React from "react"
import classnames from "classnames"
import { Text, Button } from "components"
import Sections from "./Sections"

import { Item, DeactivatedItem } from "./Items"
import withMenuItemState from "./withMenuItemState"

class MenuSearchResults extends React.Component {
  render() {
    const totalLength = Object.keys(this.props.searchMenu).reduce(
      (acc, curr) => (acc += this.props.searchMenu[curr].length),
      0
    )
    return (
      <div
        className={classnames("", {
          "flex-row flex-apart flex-align-start": !this.props.isMobile,
          "menu-items__container": this.props.isMobile,
        })}
      >
        <div
          className={classnames("flex-col flex-align-start w100 r mt-8", {
            "flex-align-center": this.props.isMobile,
            "mr-5": !this.props.isMobile,
          })}
        >
          <div className="flex-row flex-apart flex-align-cente w100">
            <Text weight="black" color="blue" size="xl">
              Search Results ({totalLength})
            </Text>
            <Button
              type="secondary"
              color="pink"
              onClick={this.props.exitSearch}
            >
              X&nbsp;&nbsp; <span style={{textDecoration:'underline'}}>Exit Search</span>
            </Button>
          </div>
          <div className="w100 mt-12">
            <div className="flex-row flex-align-center">
              <Text weight="black" color="blue" size="xl">
                Items
              </Text>
              <Text color="grey" size="xs" className="ml-3">
                {`${this.props.searchMenu.items.length} items in this section`}
              </Text>
            </div>
            {!!this.props.searchMenu.items && (
              <Item
                items={this.props.searchMenu.items}
                itemLookupTable={this.props.itemLookupTable}
                itemsSelected={this.props.itemsSelected}
                handleSelection={(id) => this.props.handleSelection(id)}
              />
            )}
            <div className="flex-row flex-align-center mt-8">
              <Text weight="black" color="blue" size="xl">
                Modifiers
              </Text>
              <Text color="grey" size="xs" className="ml-3">
                {`${this.props.searchMenu.modifiers.length} items in this section`}
              </Text>
            </div>
            {!!this.props.searchMenu.modifiers && (
              <Item
                items={this.props.searchMenu.modifiers}
                itemLookupTable={this.props.itemLookupTable}
                modifierParents={this.props.modifierParents}
                isOnModifiers={true}
                isSearch={true}
                itemsSelected={this.props.itemsSelected}
                handleSelection={(id) => this.props.handleSelection(id)}
              />
            )}

            {!!this.props.searchMenu.blackedOutItems.length && (
              <div>
                <Text weight="black" color="pink" size="xl">
                  Deactivated Items
                </Text>
                {this.props.searchMenu.blackedOutItems.map((id) => (
                  <DeactivatedItem
                    item={this.props.itemLookupTable[id]}
                    handleDeactivatedSelection={
                      this.props.handleDeactivatedSelection
                    }
                    deactivatedSelected={this.props.deactivatedSelected}
                    handleActivation={this.props.handleActivation}
                  />
                ))}
              </div>
            )}
            {!!this.props.searchMenu.blackedOutModifiers.length && (
              <div>
                <Text weight="black" color="pink" size="xl">
                  Deactivated Modifiers
                </Text>
                {this.props.searchMenu.blackedOutModifiers.map((id) => (
                  <DeactivatedItem
                    item={this.props.itemLookupTable[id]}
                    handleDeactivatedSelection={
                      this.props.handleDeactivatedSelection
                    }
                    deactivatedSelected={this.props.deactivatedSelected}
                    handleActivation={this.props.handleActivation}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="menu-categories">{this.props.DeactivationButton()}</div>
        {/* <Sections
          setSearchMenu={this.props.setSearchMenu}
          isOnModifiers={isOnModifiers}
          DeactivationButton={this.DeactivationButton}
          deactivatedItems={this.props.blackouts}
          updateSection={this.updateSection}
          fetchedMenu={this.props.fetchedMenu}
          setMenu={this.props.setMenu}
          menu={this.props.menu}
          sections={Object.keys(this.props.menu)}
          activeSectionId={this.state.activeSectionId}
        /> */}
      </div>
    )
  }
}

export default withMenuItemState(MenuSearchResults)
