import React, { Component } from 'react'
import './Loading.css'

class LoadingPage extends Component{

  render(){
    return (
      <div className='loadingPage'>
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    );
  }
}

export default LoadingPage;
