import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withMobile } from "hoc"
import DeactivateItemModal from "./DeactivateItemModal"
import { withToasts } from "toasts"
import { pluralize } from "Helpers"
import { Button } from "components"
import { activate, activateBulk, blackout } from "./menu-helpers"
const normalizeButtonText = (first, length) => {
  return `${first} ${length} ${pluralize("Item", length)}`
}

const withMenuItemState = (WrappedComponent) => {
  return withMobile(
    withToasts(
      class extends React.Component {
        static propTypes = {
          fetch: PropTypes.func.isRequired,
        }

        static defaultProps = {
          fetch: () => {},
        }

        state = {
          itemsSelected: new Set(),
          deactivatedSelected: new Set(),
          isModalShown: false,
        }

        handleActivation = async (id) => {
          this.props.setStatus({ status: "loading" })
          await activate({ id: id })
          const cb = this.props.toasts.add({
            message: "Item successfully reactivated!",
            type: "success",
          })
          this.props.fetch(cb)
        }

        handleSelection = (item_id) => {
          const { itemsSelected } = this.state
          if (itemsSelected.has(item_id)) {
            itemsSelected.delete(item_id)
          } else {
            itemsSelected.add(item_id)
          }

          this.setState({
            itemsSelected,
          })
        }

        handleDisable = () => {
          const { itemsSelected } = this.state
          if (itemsSelected.size === 0) {
            return
          }

          if (!this.state.isModalShown) {
            this.setState({ isModalShown: true })
          }
        }

        handleDeactivatedSelection = (item_id) => {
          //item already selected, remove
          const { deactivatedSelected } = this.state
          if (deactivatedSelected.has(item_id)) {
            deactivatedSelected.delete(item_id)
          } else {
            deactivatedSelected.add(item_id)
          }

          this.setState({
            deactivatedSelected,
          })
        }

        handleBulkActivation = async () => {
          this.props.setStatus({ status: "loading" })
          await activateBulk({ items: this.state.deactivatedSelected })
          const cb = () => {
            this.setState({ deactivatedSelected: new Set() }, () => {
              this.props.toasts.add({
                message: "Items successfully reactivated!",
                type: "success",
              })
            })
          }
          this.props.fetch(cb)
        }

        DeactivationButton = () => {
          const { deactivatedSelected, itemsSelected } = this.state
          const isDeactivatedSelected = deactivatedSelected.size > 0

          let innerText = ""
          const isButtonEnabled =
            this.state.itemsSelected.size > 0 || deactivatedSelected.size > 0

          if (isButtonEnabled) {
            if (this.props.activeTab === "Modifiers") {
              innerText = isDeactivatedSelected ? "Activate" : "Deactivate"
            } else {
              innerText = isDeactivatedSelected
                ? normalizeButtonText("Activate", deactivatedSelected.size)
                : normalizeButtonText("Deactivate", itemsSelected.size)
            }
          } else {
            innerText =
              this.props.activeTab === "Modifiers"
                ? "Deactivate"
                : "Deactivate Items"
          }

          return (
            <Button
              type="primary"
              color="pink"
              className={classnames("ph-7 pv-3", {
                "deactivate-mobile": this.props.isMobile,
              })}
              size="l"
              onClick={
                isDeactivatedSelected
                  ? this.handleBulkActivation
                  : this.handleDisable
              }
              disabled={!isButtonEnabled}
            >
              {innerText}
            </Button>
          )
        }

        handleBlackout = async ({ date, time }) => {
          const len = this.state.itemsSelected.size
          this.props.setStatus({ status: "loading" })
          const response = await blackout({
            items: this.state.itemsSelected,
            date,
            time,
          })
          if (response.status === "success") {
            const cb = () =>
              this.setState(
                {
                  isModalShown: false,
                  itemsSelected: new Set(),
                },
                () => {
                  this.props.toasts.add({
                    message: `${pluralize(
                      "Item",
                      len
                    )} successfully deactivated!`,
                    type: "success",
                  })
                }
              )
            this.props.fetch(cb)
          }
        }

        closeModal = () => {
          if (this.state.isModalShown) {
            this.setState({ isModalShown: false })
          }
        }

        render() {
          const funcs = {
            handleActivation: this.handleActivation,
            handleSelection: this.handleSelection,
            handleDisable: this.handleDisable,
            handleDeactivatedSelection: this.handleDeactivatedSelection,
            handleBulkActivation: this.handleBulkActivation,
            DeactivationButton: this.DeactivationButton,
            handleBlackout: this.handleBlackout,
            closeModal: this.closeModal,
          }

          return (
            <>
              {this.state.isModalShown && (
                <DeactivateItemModal
                  amountSelected={this.state.itemsSelected.size}
                  handleBlackout={this.handleBlackout}
                  itemsSelected={this.state.itemsSelected}
                  close={this.closeModal}
                />
              )}
              <WrappedComponent {...this.props} {...this.state} {...funcs} />
            </>
          )
        }
      }
    )
  )
}

export default withMenuItemState
