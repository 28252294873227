import React, { Component } from 'react';
import Integration from './Integration'
import NewIntegration from './NewIntegration'
import './Integrations.css';

class Integrations extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      integrations: {}
    }
  }

  componentDidMount(){
    if (this.props.integrations){
      this.setState({integrations: this.props.integrations})
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.integrations !== this.props.integrations){
      this.setState({integrations: this.props.integrations})
    }
  }

  integrationSuccess(){
    this.props.refreshData()
    this.setState({new_remote_site: null})
  }

  renderIntegrations(){
    let integrations = []
    for (var integration in this.state.integrations){
      integrations.push(<Integration key={integration} integration={this.state.integrations[integration]}/>)
    }
    return integrations
  }

  render(){
    return (
      <div className="Integrations">
        <img className='w96px' src='/burger.png' alt='footer'/>
        <h1>Integrations</h1>
        <p>Link your external accounts here to see more data and insights on your Feed.</p>
        {this.renderIntegrations()}
        <NewIntegration rest={this.props.rest} refreshData={()=>this.props.refreshData()} integrationSuccess={()=>this.integrationSuccess()}/>
      </div>
    )
  }
}

export default Integrations
