import React, { Component } from 'react';
import NewsCard from '../Card/NewsCard/NewsCard'
import PostCard from '../Card/PostCard/PostCard'
import Grubcard from '../Card/Grubcard/Grubcard'
import CustomerCard from '../Card/CustomerCard/CustomerCard'
import LoadingPage from '../Loading/LoadingPage'
import './Feed.css';
const API = require('../api')

class Feed extends Component {

  constructor(props){
    super(props)
    this.state = {
      stories:[],
      content:null
    }
  }

  componentDidMount(){
    window.addEventListener("resize", ()=>this.renderColumns())
    API.call(
      'get_stories',
      (res)=>this.handleAPISuccess(res),
      (err)=>this.handleAPIFailure(err),
      null,
      null
    )
  }

  handleAPISuccess(res){
    this.setState({stories:res},()=>this.renderColumns())
  }

  handleAPIFailure(err){
    console.log(err)
  }

  componentDidUpdate(prevProps){
    if (this.props.stories !== prevProps.stories){
      this.setState({stories:this.props.stories},()=>this.renderColumns())
    }
    if (this.props.rest && prevProps.rest){
      if (this.props.rest.name !== prevProps.rest.name){
        this.renderColumns()
      }
    }
  }

  renderColumns(){
    if (this.state.stories){
      const CARD_WIDTH = 400
      let numColumns = Math.floor(document.getElementById('AppBody').offsetWidth/CARD_WIDTH)
      if (numColumns < 1) numColumns=1

      let columns = Array(numColumns).fill([])
      for (var col in columns) columns[col] = []
      this.state.stories.forEach((story, i)=>{
        if (story.story_type === 'article'){
          columns[i % columns.length].push(<NewsCard key={i} story={story}/>)
        }
        if (story.story_type === 'post'){
          columns[i % columns.length].push(<PostCard key={i} story={story}/>)
        }
      })
      //let grubhub_integration = false
      // grubhub integration data no longer works, so do not show
      // the card anymore, (ie grubhub_integration = false)
      //for (let i in this.props.integrations){
      //  if (this.props.integrations[i].remote_site === 'GrubHub'){
      //    grubhub_integration = true
      //    break
      //  }
      //}
      //columns[numColumns > 1 ? 1 : 0].splice(1, 0, <Grubcard key={-1} integration={grubhub_integration} rest={this.props.rest}/>)
      //columns[numColumns > 2 ? 2 : 0].splice(2, 0, <CustomerCard key={-2} integration={grubhub_integration} rest={this.props.rest}/>)

      let content = columns.map((column,i)=>{
        return (
          <div key={i} className='inline-block' style={{width:`${Math.floor(100/columns.length)}%`}}>
            {column}
          </div>
        )
      })
      this.setState({content}) 
    }
  }

  render(){
    if (this.state.stories.length){
      return (
        <div className="Feed" id ='Feed'>
          {this.state.content}
          <br/>
          <br/>
          <img className='FeedFooterImg' src='/taco.png' alt='footer'/>
          <div className='pv8 fs16 fw700 c222 inline-block w100'>
            That's all of
            <strong className='fw900'> The Feed </strong>
            for now,<br/>check back soon for more insights!
          </div>
        </div>
      )
    }
    else{
      return <LoadingPage/>
    }
    
  }
}

export default Feed;
