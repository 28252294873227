import React, { Component } from "react"
import Card from "../Card"
import Modal from "../../Modal/Modal"
import { Link } from "react-router-dom"
import Loading from "../../Loading/Loading"
import NumberFlipper from "../../NumberFlipper/NumberFlipper"
import "./CustomerCard.css"

const API = require("../../api")

class CustomerCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      customers: [],
      expanded: false,
    }
  }

  componentDidMount() {
    if (this.props.rest) {
      this.getInsights()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rest.name !== this.props.rest.name) {
      this.getInsights()
    }
  }

  getInsights() {
    API.call(
      "customers",
      (res) => this.handleAPISuccess(res),
      (err) => this.handleAPIFailure(err),
      { rest_id: this.props.rest.restaurant_id },
      null
    )
  }

  handleAPISuccess(res) {
    this.setState({
      loading: false,
      customers: res.customers,
    })
  }

  handleAPIFailure(err) {
    console.log("FAILURE:", err)
    this.setState({ loading: false })
  }

  expandCard() {
    this.setState({ expanded: true })
  }

  aggregate(array) {
    let sum = 0
    for (let item in array) {
      sum += array[item]
    }
    return Math.round(sum * 100) / 100
  }

  renderExpansion() {
    if (this.state.expanded) {
      return (
        <Modal
          close={() => this.setState({ expanded: false })}
          style={{ background: "#218B5B", cursor: "pointer" }}
        >
          <div className="CustomerCardHeader">
            <img
              className="CustomerCardLogo"
              src="/mvp.png"
              alt="top 3rd party customers"
            />
            <div className="fs28 fw900 left mv8 textshadow">
              Top 3rd Party Customers
            </div>
          </div>
          {this.renderContent(true)}
        </Modal>
      )
    }
  }

  renderContent(isExpansion) {
    if (!this.props.integration) {
      return (
        <div className="center">
          <img src="/error.png" className="w96px" alt="error" />
          <div className="fw500 fs16 cfff pv8 ph16">
            Connect your 3rd patry accounts to access more data and insights:
          </div>
          <Link to="/integrations">
            <button className="button mv16">CONNECT MY ACCOUNTS</button>
          </Link>
        </div>
      )
    }
    if (this.state.loading) {
      return <Loading />
    }
    let display_num = isExpansion ? 100 : 5
    return (
      <div>
        {this.state.customers.map((customer, i) => {
          if (i < display_num) {
            return (
              <div className="CustomerCardCustomer left ph16 pv16" key={i}>
                <img
                  src="/user.png"
                  className="CustomerCardCustomerImg"
                  alt="user"
                />
                <div
                  className="inline-block left ph8 pv4"
                  style={{ maxWidth: "180px" }}
                >
                  <div className="fw900 fs16 cfff">
                    {customer.first_name} {customer.last_name}
                  </div>
                  <div className="fw300 fs12 cfff">
                    {customer.street1} {customer.street2}
                  </div>
                </div>
                <div className="CustomerCardCustomerOrders">
                  <NumberFlipper
                    number={customer.subtotal.toFixed(2)}
                    color="#333"
                    scale=".6"
                    units="$"
                  />
                  <div className="CustomerCardCustomerCaption m0 p0 cfff fs12">
                    <span className="fw900 textshadow">
                      {customer.num_orders}&nbsp;
                    </span>
                    {customer.num_orders === 1 ? "order" : "orders"} this year
                  </div>
                </div>
              </div>
            )
          } else return null
        })}
        {!isExpansion && <button className="button mv16">VIEW TOP 100</button>}
      </div>
    )
  }

  render() {
    if (this.props.rest && this.state.customers.length) {
      return (
        <div id="CustomerCard">
          <Card
            style={{ background: "#218B5B", cursor: "pointer" }}
            onClick={() => this.expandCard()}
          >
            <div className="CustomerCardHeader">
              <img
                className="CustomerCardLogo"
                src="/mvp.png"
                alt="top 3rd party customers"
              />
              <div className="fs28 fw900 left mv8 textshadow">
                Top 3rd Party Customers
              </div>
            </div>
            {this.renderContent(false)}
          </Card>
          {this.renderExpansion()}
        </div>
      )
    } else return null
  }
}

export default CustomerCard
