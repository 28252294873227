import React from "react"
import { withToasts } from "toasts"
import OrderSettings from "./OrderSettings"
import { Tabs } from "components"

const API = require("../../api.js")

const T = {
  ORDER: "Order Settings",
}

class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hours: null,
    }
    this.tabMap = this.tabMap.bind(this)
  }

  tabMap = () => ({
    'Order Settings' : (
      <OrderSettings
        loadRest={this.props.loadRest}
      />
    )
  })

  render() {
    return (
    <div>
      <Tabs
        tabMap={this.tabMap()}
        initialTab="Order Settings"
        childClass="flex-col flex-align-start p-3"
      />
    </div>
    )
  }
}


export default withToasts(Settings)
