import React from "react"
import classnames from "classnames"
import moment from "moment"
import { withMobile } from "hoc"
import { normalizeImageUrl, formatPrice } from "Helpers"
import { Text, Button, ButtonWrapper } from "components"

const DEACTIVATED_NAMES = ["Deactivated Items", "Deactivated Modifiers"]
const Items = withMobile((props) => {
  const {
    handleActivation,
    deactivatedSelected,
    handleDeactivatedSelection,
    modifierParents,
    itemLookupTable,
    deactivatedItems
  } = props

  return (
    <div className="flex-col y-auto w100 mt-8">
      <ItemList {...props} />
      <DeactivatedItems
        deactivatedItems={deactivatedItems}
        isOnModifiers={props.isOnModifiers}
        items={props.blackouts}
        modifierParents={modifierParents}
        itemLookupTable={itemLookupTable}
        deactivatedSelected={deactivatedSelected}
        handleDeactivatedSelection={handleDeactivatedSelection}
        handleActivation={handleActivation}
      />
    </div>
  )
})

export const ItemList = (props) => {
  const {
    activeSectionId,
    menu,
    isOnModifiers,
    itemLookupTable,
    modifierParents,
    itemsSelected,
    handleSelection,
  } = props
  const showCategories = activeSectionId ? [activeSectionId] : Object.keys(menu)
  // Handles race condition where user clicks on a category that isnt present in the other tab, but the state hasnt updated yet
  if (activeSectionId && !menu[activeSectionId]) return null
  return (
    !DEACTIVATED_NAMES.includes(activeSectionId) &&
    showCategories.map((category, i) => {
      const items = Array.isArray(menu[category])
        ? menu[category]
        : menu[category].items

      const type = isOnModifiers ? 'modifiers': 'items'

      return (
        <div className="mt-4" key={i}>
          <div className="flex-row flex-align-center">
            <Text color="blue" weight="black" size="xl">
              {category}
            </Text>
            <Text color="grey" size="xs" className="ml-3">
              {`${items.length} ${type} in this section`}
            </Text>
            {/* {i === 0 && itemsSelected.size > 0 && (
              <Text color="pink" weight="extrabold" className="ml-auto">
                {`${itemsSelected.size} items selected`}
              </Text>
            )} */}
          </div>
          <Item
            items={items}
            isOnModifiers={isOnModifiers}
            modifierParents={modifierParents}
            itemLookupTable={itemLookupTable}
            itemsSelected={itemsSelected}
            handleSelection={handleSelection}
          />
        </div>
      )
    })
  )
}

// this is actually a category, not an Item
class Item extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      expandedModifiers:[]
    }
  }
  
  render(){
   let {items, 
     handleSelection, 
     itemsSelected, 
     itemLookupTable,
     isOnModifiers,
     modifierParents,
     isSearch
   } = {...this.props}
    return items.map((currItem, j) => {
      const id = typeof currItem === "object" ? currItem.menu_item_id : currItem
      const item = itemLookupTable[id]
      let parents = []
      let parents_str = ""
      let num_parents = 0
      if (isOnModifiers){
        parents = modifierParents[id]
        if(parents){
          let parentNames = []
          for(let i in parents){
            let name = itemLookupTable[parents[i]].name
            parentNames.push(name)
          }
          parents_str = parentNames.join(", ")
          num_parents = parents.length
        }
      }
      if (!item) {
        return null
      }
      const u = normalizeImageUrl({
        url: item.image_url,
        size: { w: 120, h: 120 },
      })
      return (
        <button
          key={item.menu_item_id}
          className={isOnModifiers && isSearch ? "items__item-container-half-height" : "items__item-container"}
          onClick={() => handleSelection(item.menu_item_id)}
        >
          <span
            aria-checked={itemsSelected.has(item.menu_item_id)}
            aria-label="checkbox"
            role="checkbox"
            className={classnames("item-checkbox", {
              "item-checkbox--checked": itemsSelected.has(item.menu_item_id),
            })}
          />
          <div className="flex-col w100 pr-4 pv-2">


            {this.state.expandedModifiers.indexOf(item.menu_item_id) > -1 ? (
              <div style={{marginTop:'9px'}}> &nbsp; </div>
            ) : null }

            <div className="flex-row flex-apart">
              <Text weight="black" size="l">
                {item.name}
              </Text>
              <Text weight="extrabold" className="items__price">
                {item.price ? formatPrice(item.price) : ''}
              </Text>
            </div>
            {item.description && (
              <Text color="grey" className="mt-1">
                {item.description}
              </Text>
            )}

            {isOnModifiers && parents_str ? (
            <div className='modifier-parents'>
              <div className='modifier-parent'>
                <span onClick={(e)=>{
                  e.stopPropagation()
                  let expandedModifiers = this.state.expandedModifiers
                  if(expandedModifiers.indexOf(item.menu_item_id) === -1){
                    expandedModifiers.push(item.menu_item_id)
                  } else{
                    var index = expandedModifiers.indexOf(item.menu_item_id);
                    expandedModifiers.splice(index, 1);
                  }
                  this.setState({expandedModifiers:expandedModifiers})
                }}
                >
                  <span style={{textDecoration:'underline'}}>
                    In {num_parents} item{num_parents > 1 ? 's' : ''}
                  </span>

                {this.state.expandedModifiers.indexOf(item.menu_item_id) === -1 ? (
                  <>&nbsp;&nbsp;&gt;</>
                ) : <>&nbsp;&nbsp;v</>}
                </span>
                {this.state.expandedModifiers.indexOf(item.menu_item_id) > -1 ? (
                  <div style={{marginTop:'9px'}}> {parents_str} </div>
                ) : null }
              </div>
            </div> ) : null}

          </div>
          {item.image_url && <img src={u} alt="food" />}
        </button>
      )
    })
  }
}

const DeactivatedItems = withMobile((props) => {
  const {
    isMobile,
    items,
    itemLookupTable,
    isOnModifiers,
    modifierParents,
    handleActivation,
    handleDeactivatedSelection,
    deactivatedSelected,
    deactivatedItems
  } = props
  return (
    <div className="mt-4">
      <Text weight="black" color="pink" size="xl">
        {isOnModifiers ? "Deactivated Modifiers" : "Deactivated Items"}
      </Text>
      {items.length === 0 ? (
        <div className="flex-row flex-align-center flex-justify-center mt-4">
          <img
            src="https://imgcdn.hngr.co/Popsicle.png?size=100x100"
            className="items__empty-deactivated"
            alt="popsicle"
          />
          <Text size="l" weight="bold" className="p-4">
            There are no deactivated menu items
          </Text>
        </div>
      ) : (
        items.map((id) => {
          const item = itemLookupTable[id]
          return (
            <DeactivatedItem
              item={item}
              modifierParents={modifierParents}
              deactivatedItems={deactivatedItems}
              itemLookupTable={itemLookupTable}
              isOnModifiers={isOnModifiers}
              deactivatedSelected={deactivatedSelected}
              handleActivation={handleActivation}
              handleDeactivatedSelection={handleDeactivatedSelection}
            />
          )
        })
      )}
    </div>
  )
})

class DeactivatedItem  extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      expandedModifiers: []
    }
  }

  render(){
    let {
      item,
      handleDeactivatedSelection,
      deactivatedSelected,
      handleActivation,
      isOnModifiers,
      itemLookupTable,
      modifierParents,
      deactivatedItems
    } = {...this.props}

    let isMobile = window.innerWidth <= 800

    if (!item) return null
    // deactivatedItems is some list of items from somewhere.... 
    // it has the blackout_until field. So to find the blackout_until
    // we need to loop through it and find the matching menu_item_id
    let blackout_until = null
    for(var i in deactivatedItems){
      if(deactivatedItems[i].menu_item_id == item.menu_item_id){
        blackout_until = deactivatedItems[i].blackout_until
      }
    }
    
    const id = item.menu_item_id
    const date = moment(blackout_until).format("MMM D, YYYY")
    let parents = []
    let parents_str = ""
      let num_parents = 0
    if (isOnModifiers){
      parents = modifierParents[id]
      if(parents){
        let parentNames = []
        for(let i in parents){
          let name = itemLookupTable[parents[i]].name
          parentNames.push(name)
        }
        parents_str = parentNames.join(", ")
        num_parents = parents.length
      }
    }

    return (
      <ButtonWrapper
        className="items__item-container items__item-container--deactivated"
        onClick={() => handleDeactivatedSelection(id)}
        key={item.menu_item_id}
      >
        <span
          aria-checked={deactivatedSelected.has(id)}
          aria-label="checkbox"
          role="checkbox"
          className={classnames("item-checkbox item-checkbox--deactivated", {
            "item-checkbox--checked": deactivatedSelected.has(
              item.menu_item_id
            ),
          })}
        />
        <div
          className={classnames("w100", {
            "flex-row": !isMobile,
          })}
        >
          <div
            style={{justifyContent:'center'}}
            className={classnames("flex-col w100", {
              "mh-4": !isMobile,
            })}
          >
            <div>

            {this.state.expandedModifiers.indexOf(item.menu_item_id) > -1 ? (
              <div style={{marginTop:'6px', display:'block'}}> &nbsp; </div>
            ) : null }

             <div className="flex-row flex-apart flex-align-center">
               <div className="flex-row flex-align-center mr-4">
                  <Text weight="black" size="l">
                    {item.name}
                  </Text>
                  <Text
                    className="menu-item__tag bg-pink ml-2"
                    color="white"
                    weight="bold"
                    size="xs"
                  >
                    Deactivated
                  </Text>
                </div>
                <Text weight="extrabold" className="items__price">
                  {formatPrice(item.price)}
                </Text>
              </div>
              {item.description && (
                <Text color="grey" className="mt-1">
                  {item.description}
                </Text>
              )}
            </div>

            {isOnModifiers && parents_str ? (
            <div className='modifier-parents'>
              <div className='modifier-parent'>
                <span onClick={(e)=>{
                  e.stopPropagation()
                  let expandedModifiers = this.state.expandedModifiers
                  if(expandedModifiers.indexOf(item.menu_item_id) === -1){
                    expandedModifiers.push(item.menu_item_id)
                  } else{
                    var index = expandedModifiers.indexOf(item.menu_item_id);
                    expandedModifiers.splice(index, 1);
                  }
                  this.setState({expandedModifiers:expandedModifiers})
                }}
                >
                  <span style={{textDecoration:'underline'}}>
                    In {num_parents} item{num_parents > 1 ? 's' : ''}
                  </span>

                {this.state.expandedModifiers.indexOf(item.menu_item_id) === -1 ? (
                  <>&nbsp;&nbsp;&gt;</>
                ) : <>&nbsp;&nbsp;v</>}
                </span>
                {this.state.expandedModifiers.indexOf(item.menu_item_id) > -1 ? (
                  <div style={{marginTop:'9px'}}> {parents_str} </div>
                ) : null }
              </div>
            </div> ) : null}

          </div>
          <div
            className={classnames("flex-align-center", {
              "flex-row w100 flex-apart mt-auto": isMobile,
              "flex-col": !isMobile,
            })}
          >
            <Text size="s" color="blue">
              Returns: {date}
            </Text>
            <Button
              type="primary"
              fontSize="m"
              color="pink"
              onClick={() => handleActivation(id)}
              className="no-wrap pv-2 ph-8 mt-4"
            >
              {isOnModifiers ? "Activate Modifier" : "Activate Item"}
            </Button>
          </div>
        </div>
      </ButtonWrapper>
    )
  }
}



export {Items, Item, DeactivatedItem}
