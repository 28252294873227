import React, { Component } from "react"
import Orders from "./Orders"
import Summaries from "./Summaries"
import Payouts from "./Payouts"
import Transactions from "./Transactions"
import Reconcilliation from "./Reconcilliation"
import Diners from "./Diners"
import "./Finance.css"

class Finance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: "Orders",
    }
  }

  renderToggles() {
    return ["Orders", "Summaries", "Transactions",  "Payouts", "Diners"].map(
      (t, i) => (
        <button
          key={i}
          style={{
            color: t === this.state.toggle ? "#fff" : "#4284f4",
            borderColor: t === this.state.toggle ? "#fff" : "#4284f4",
          }}
          className={
            t === this.state.toggle ? "button mh16" : "button secondary mh16"
          }
          onClick={() => this.setState({ toggle: t })}
        >
          {t}
        </button>
      )
    )
  }

  renderContent() {
    if (this.state.toggle === "Orders") return <Orders {...this.props} />
    if (this.state.toggle === "Summaries") return <Summaries {...this.props} />
    if (this.state.toggle === "Payouts") return <Payouts {...this.props} />
    if (this.state.toggle === "Transactions")
      return <Transactions {...this.props} />
    if (this.state.toggle === "Diners") return <Diners {...this.props} />
    if (this.state.toggle === "Reconcilliation") 
      return <Reconcilliation {...this.props} />
  }

  render() {
    return (
      <div className="Finance">
        {this.renderToggles()}
        <br />
        {this.renderContent()}
      </div>
    )
  }
}

export default Finance
