import React from "react"
import moment from "moment"
import { withMobile } from "hoc"
import { Text, Button, ButtonWrapper } from "components"
import { ReactComponent as PrepAdd } from "assets/icons/prep-add.svg"
import { ReactComponent as PrepSub } from "assets/icons/prep-sub.svg"

const PrepTimePicker = withMobile((props) => {
  const {
    pickupValue,
    updatePickup,
    deliveryValue,
    updateDelivery,
    isMobile,
  } = props
  return (
    <div className={isMobile && "w100"}>
      <div className="prep-time-row">
        <Text weight="black" className="mr-4">
          Prep + Pickup
        </Text>
        <div className="prep-actions">
          <ButtonWrapper
            onClick={() => {
              if (pickupValue > 5) updatePickup(pickupValue - 5)
            }}
          >
            <PrepSub />
          </ButtonWrapper>
          <div className="prep-time-value">{`${pickupValue} min`}</div>
          <ButtonWrapper onClick={() => updatePickup(pickupValue + 5)}>
            <PrepAdd />
          </ButtonWrapper>
        </div>
      </div>

      <div className="prep-time-row">
        <Text weight="black" className="mr-4">
          Prep + Delivery
        </Text>
        <div className="prep-actions">
          <ButtonWrapper
            onClick={() => {
              if (deliveryValue > 5) updateDelivery(deliveryValue - 5)
            }}
          >
            <PrepSub />
          </ButtonWrapper>
          <div className="prep-time-value">{`${deliveryValue} min`}</div>
          <ButtonWrapper onClick={() => updateDelivery(deliveryValue + 5)}>
            <PrepAdd />
          </ButtonWrapper>
        </div>
      </div>
    </div>
  )
})

export default PrepTimePicker 
