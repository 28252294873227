import React from "react"
import { withMobile } from "hoc"
import { Text, Button, ButtonWrapper } from "components"
import PrepTimePicker from '../hours/PrepTimePicker'
import Loading from "../../Loading/Loading"
import classnames from "classnames"
const API = require("../../api.js")

class OrderSettings extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      prepTimesLoaded:false
    }
    this.loadPrepTime()
  }

  async loadPrepTime() {
    let rest = await this.props.loadRest()
    this.rest = rest
    API.call(
      "default-prep-time-get",
      (resp) => {
        this.setState({ 
          prepTimesLoaded:true,
          pickupPrepTime: resp.default_pickup_time, 
          deliveryPrepTime: resp.default_delivery_time
        })
      },
      (err) => {
        alert("Error loading prep time.")
      },
      null,
      { rest_id: rest.restaurant_id }
    )
  }

  async savePrepTime(type) {
    let rest = this.rest
    let minutes
    if (type === "delivery") {
      minutes = this.state.deliveryPrepTime
    } else if (type === "pickup") {
      minutes = this.state.pickupPrepTime
    }

    API.call(
      "default-prep-time-set",
      (resp) => {},
      (err) => {
        alert("Error saving prep time.")
        this.loadPrepTime()
      },
      null,
      { rest_id: rest.restaurant_id, type: type, minutes: minutes }
    )
  }

  render() {
    return(
      <Section className="flex-col flex-align-start">
        <Text weight="black" size="xl" className="w100" align="left">
          Default Prep Time
        </Text>
        {this.state.prepTimesLoaded ? (
          <PrepTimePicker
            pickupValue={this.state.pickupPrepTime}
            updatePickup={(val) =>
              this.setState({ pickupPrepTime: val }, () =>
                this.savePrepTime("pickup")
              )
            }
            deliveryValue={this.state.deliveryPrepTime}
            updateDelivery={(val) =>
              this.setState({ deliveryPrepTime: val }, () =>
                this.savePrepTime("delivery")
              )
            }
          /> ) : <Loading/>}
      </Section>
    )
  }
}

const Section = ({ children, className }) => (
  <div className={classnames("mt-10 w100", { [className]: className })}>
    {children}
  </div>
)

export default OrderSettings 

