import React, { Component } from 'react';
import './Card.css';

class Card extends Component {
  render(){
    return (
      <div
      className="Card"
      onClick={this.props.onClick ? ()=>this.props.onClick() : null}
      style={{...this.props.style}}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Card;
