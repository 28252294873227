import React from "react"
import moment from "moment"
import classnames from "classnames"
import { pluralize } from "Helpers"

import { Text, Button } from "components"
import { withMobile } from "hoc"
import Modal from "Modal/Modal"

const DeactivateItemModal = (props) => {
  let dateInput = React.useRef(null)
  const [date, setDate] = React.useState("")
  const [isText, setIsText] = React.useState(true)
  const [formattedDate, setFormattedDate] = React.useState("")
  const today = moment()

  const handleFocus = (e, targetValue) => {
    if (!e.target.value) {
      e.target.type = targetValue
    }

    /*
      When the user clicks on the date option, need to change the type back to date and set the value as the unformatted date
      */
    if (targetValue === "date") {
      setIsText(false)
    }
  }

  /*
  When user clicks off, need to change the date input type to text and set the value to formattedDate
  */
  const handleBlur = (e) => {
    if (!e.target.value) {
      e.target.type = "text"
    }

    if (e.target.type === "date") {
      // e.target.type = "text"
      e.target.value = formattedDate
      setIsText(true)
    }
  }

  const setValue = (e, targetValue) => {
    // e.persist()
    if (targetValue === "date") {
      setIsText(false)
      setDate(e.target.value)
      const formatted = moment(e.target.value).format("MMM D, YYYY")
      setFormattedDate(formatted)
    }
  }

  const isDateToday = () => {
    const today = moment()
    return !!formattedDate && !!date && today.isSame(moment(date), "day")
  }

  const setToday = () => {
    // if Date is aleady set, reset it to null
    if (isDateToday()) {
      setFormattedDate("")
      setDate("")
      setIsText(true)
      return
    }
    setFormattedDate(today.format("MMM D, YYYY"))
    setDate(today.format("YYYY-MM-DD"))
    setIsText(true)
  }

  const handleBlackoutPropogation = () => {
    props.handleBlackout({
      date: date,
    })
  }

  return (
    <Modal
      close={props.close}
      className="p-6"
      bgClassName="menu__deactivate-modal"
    >
      <div>
        <Text weight="black" size="l">
          {`Deactivate ${props.amountSelected} ${pluralize(
            "Item",
            props.amountSelected
          )} Until:`}
        </Text>
        <div className="flex-col flex-align-center mt-8">
          <Button type="primary-inverted" onClick={setToday}>
            Today
          </Button>
          <Text weight="black" className="mv-4">
            Or:
          </Text>
          <div
            className={classnames("w100 flex-row flex-evenly", {
              "flex-wrap": props.isMobile,
            })}
          >
            <input
              placeholder="Date"
              type={isText ? "text" : "date"}
              value={isText ? formattedDate : date}
              onChange={(e) => setValue(e, "date")}
              onFocus={(e) => handleFocus(e, "date")}
              onBlur={(e) => handleBlur(e)}
              ref={dateInput}
              min={today.format("YYYY-MM-DD")}
              className="no-ryan-input input--b-grey flex-basis-35"
            />
          </div>

          <Text
            size="s"
            weight="regular"
            color="grey"
            className="mt-12"
            align="center"
          >
            {!!date
              ? `Deactivated items will be unavailable through ${moment(date)
                  .add(1, "day")
                  .format("MMM D")} at 5:00am.`
              : `Deactivated items will be unavailable through the next day at 5:00am.`}
          </Text>
        </div>
        <div className="deactivate__actions pt-6">
          <Button
            type="secondary"
            onClick={props.close}
            className="mr-6"
            fontSize="l"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!date}
            onClick={handleBlackoutPropogation}
          >
            Deactivate
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default withMobile(DeactivateItemModal)
