import PropTypes from "prop-types"
import React from "react"
import MenuItemsSearch from "./MenuItemsSearch"

import { Button, Tabs } from "components"
import classnames from "classnames"
import { withMobile } from "hoc"
import { withToasts } from "toasts"
import { pluralize } from "Helpers"
import withMenuItemState from "./withMenuItemState"
import {Items} from "./Items"
import MenuSearchResults from "./MenuSearchResults"
import LoadingPage from "Loading/LoadingPage"
import DeactivateItemModal from "./DeactivateItemModal"
import Sections from "./Sections"
import { fetchItems, activate, blackout, activateBulk } from "./menu-helpers"

import "./menu.scss"

const normalizeButtonText = (first, length) => {
  return `${first} ${length} ${pluralize("Item", length)}`
}

class Menu extends React.Component {
  state = {
    menu: null,
    searchMenu: { items: [], modifiers: [] },
    fetchedMenu: null,
    status: "idle",
    isSearchActive: false,
  }

  componentDidMount() {
    this.setState({ status: "loading" })
    this.fetch()
  }

  fetch = async (cb = () => {}) => {
    const data = await fetchItems({ subdomain: this.props.rest.subdomain })
    cb()
    this.initiateMenu(data)
    if (this.state.isSearchActive) {
      this.setState({ isSearchActive: false })
    }
  }

  // This function SUCKS im sorry
  initiateMenu = (data) => {
    const blackouts = data.blackouts
    const activatedList = data.menu.menu
    let deactivatedList = []
    const a = Object.keys(activatedList).reduce((acc, key) => {
      const items = activatedList[key].items

      //Need to filter instead of splice bc you shouldnt splice while iterating through the array
      const i = items.filter((item) => {
        //item is currently blackedout
        //check to see if today is THE SAME OR BEFORE THE BLACKOUT DATE

        if (item.blackout_until && blackouts.includes(item.menu_item_id)) {
          console.log("poop", item)
          deactivatedList.push(item)
          return false
        } else {
          return true
        }
      })
      acc[key] = { ...activatedList[key], items: i }
      return acc
    }, {})
    let blackedOutModifiers = []
    let blackedOutItems = []

    const allModifiers = Object.keys(data.menu.modifiers).reduce(
      (acc, curr) => {
        acc.push(...data.menu.modifiers[curr])
        return acc
      },
      []
    )

    //we need to split up the blackout out items into
    //If the modifers, an array of menu ids, contains the menu id, it is a modifier blackout so push it there, else push to item blackouts
    blackouts.forEach((item_id) => {
      if (allModifiers.includes(item_id)) {
        blackedOutModifiers.push(item_id)
      } else {
        blackedOutItems.push(item_id)
      }
    })

    //Filter out the modifiers that are blacked out
    const filteredModifiers = Object.keys(data.menu.modifiers).reduce(
      (acc, category) => {
        const activeModifiers = data.menu.modifiers[category].filter(
          (id) => !blackedOutModifiers.includes(id)
        )
        acc[category] = activeModifiers
        return acc
      },
      {}
    )

    // const searchMenu = {ITEME}

    this.setState(
      {
        menu: a,
        itemLookupTable: data.menu.menu_items,
        modifiers: filteredModifiers,
        modifierParents:data.menu.modifier_parents,
        fetchedMenu: a,
        deactivatedItems: deactivatedList,
        blackedOutModifiers: blackedOutModifiers,
        blackedOutItems: blackedOutItems,
        status: "ready",
      },
      () => console.log(this.state)
    )
  }

  setSearchMenu = (menu) => {
    if (!menu) {
      this.setState({ isSearchActive: false })
      return
    }
    this.setState({
      searchMenu: menu,
      isSearchActive: true,
    })
  }

  setStatus = ({ status }) => {
    this.setState({ status: status })
  }

  handleTabClick = () => {
    if (this.state.isSearchActive) {
      this.setState({ isSearchActive: false })
    }
  }

  render() {
    const tabMap = {
      Items: (
        <MenuItems
          {...this.props}
          {...this.state}
          blackouts={this.state.blackedOutItems}
          menu={this.state.menu}
          setSearchMenu={(menu) => this.setSearchMenu(menu)}
          setStatus={this.setStatus}
          setMenu={(menu) => this.setState({ menu: menu })}
          fetch={this.fetch}
        />
      ),
      Modifiers: (
        <MenuItems
          {...this.props}
          {...this.state}
          blackouts={this.state.blackedOutModifiers}
          menu={this.state.modifiers}
          isModifiers={true}
          modifierParents={this.state.modifierParents}
          itemLookupTable={this.state.itemLookupTable}
          setStatus={this.setStatus}
          setSearchMenu={(menu) => this.setSearchMenu(menu)}
          setMenu={(menu) => this.setState({ menu: menu })}
          fetch={this.fetch}
        />
      ),
    }
    return (
      <div class="rel">
        <div className="flex-row flex-align-center w80 mq__flex-wrap mq__flex-col mb-8">
          <MenuItemsSearch
            menu={this.state.fetchedMenu}
            setSearchMenu={(newMenu) => this.setSearchMenu(newMenu)}
            itemLookupTable={this.state.itemLookupTable}
            blackedOutItems={this.state.blackedOutItems}
            blackedOutModifiers={this.state.blackedOutModifiers}
            modifiers={this.state.modifiers}
            items={this.state.menu}
            isSearchActive={this.state.isSearchActive}
          />
        </div>
        <Tabs
          tabMap={tabMap}
          initialTab="Items"
          shouldPreventRender={this.state.isSearchActive}
          // setTabOn={{ condition: this.state.isSearchActive, tab: "Modifiers" }}
          onTabClick={this.handleTabClick}
        />

        {this.state.isSearchActive && (
          <MenuSearchResults
            activeTab="Modifiers"
            setStatus={this.setStatus}
            fetch={this.fetch}
            exitSearch={() => this.setState({ isSearchActive: false })}
            searchMenu={this.state.searchMenu}
            modifierParents={this.state.modifierParents}
            itemLookupTable={this.state.itemLookupTable}
          />
        )}
      </div>
    )
  }
}

const MenuItems = withMenuItemState(
  class extends React.Component {
    state = {
      activeSectionId: null,
    }

    componentDidUpdate(prevProps) {
      if (this.props.activeTab !== prevProps.activeTab) {
        this.setState({ activeSectionId: null })
      }
    }

    updateSection = (section) => {
      if (section === this.state.activeSectionId) {
        this.setState({
          activeSectionId: null,
        })
      } else {
        this.setState({ activeSectionId: section })
      }

      if (window.innerWidth > 800) {
        window.scrollTo(0, 0)
      }
    }

    render() {
      if (this.props.status === "loading") {
        return <LoadingPage />
      }

      const isOnModifiers = this.props.activeTab === "Modifiers"
      return (
        this.props.status === "ready" && (
          <div className="menu__container">
            <div
              className={classnames("", {
                "flex-row flex-apart flex-align-start": !this.props.isMobile,
                "menu-items__container": this.props.isMobile,
              })}
            >
              <div
                className={classnames("flex-col flex-align-start w100", {
                  "flex-align-center": this.props.isMobile,
                  "mr-5": !this.props.isMobile,
                })}
              >
                <Items
                  isOnModifiers={isOnModifiers}
                  blackouts={this.props.blackouts}
                  deactivatedItems={this.props.deactivatedItems}
                  deactivatedSelected={this.props.deactivatedSelected}
                  handleDeactivatedSelection={
                    this.props.handleDeactivatedSelection
                  }
                  handleSelection={this.props.handleSelection}
                  itemsSelected={this.props.itemsSelected}
                  menu={this.props.menu}
                  isModifiers={true}
                  itemLookupTable={this.props.itemLookupTable}
                  modifierParents={this.props.modifierParents}
                  handleActivation={this.props.handleActivation}
                  activeSectionId={this.state.activeSectionId}
                />
              </div>
              <Sections
                setSearchMenu={this.props.setSearchMenu}
                isOnModifiers={isOnModifiers}
                categoryHeader={
                  isOnModifiers ? "Modifier Groups" : "Menu Sections"
                }
                DeactivationButton={this.props.DeactivationButton}
                deactivatedItems={this.props.blackouts}
                updateSection={this.updateSection}
                fetchedMenu={this.props.fetchedMenu}
                setMenu={this.props.setMenu}
                menu={this.props.menu}
                itemLookupTable={this.props.itemLookupTable}
                modifierParents={this.props.modifierParents}
                sections={Object.keys(this.props.menu)}
                activeSectionId={this.state.activeSectionId}
              />
            </div>
          </div>
        )
      )
    }
  }
)

export default withMobile(withToasts(Menu))
