import React, { Component } from "react"
import Loading from "../Loading/Loading"
import { withRouter } from "react-router-dom"
import { GoogleLogin } from "react-google-login"
import "./Login.css"
const API = require("../api")

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      loading: false,
      error: false,
      done: false
    }
    this.submitResetRequest = this.submitResetRequest.bind(this)
  }
 
  resetSuccess() { 
    this.setState({done: true})
  }
 
  resetFailure() {
    this.setState({error: true})
  }

  // submit the request to reset email
  submitResetRequest() {
    this.setState({loading: true})
    let payload = {email: this.state.email}
    API.call(
      "request_reset",
      (res) => this.resetSuccess(res),
      (err) => this.resetFailure(err),
      null,
      payload)

  }

  render() {
    return (
      <div className="Login">
        <div className="LoginReset" style={{padding: '10px', width: '30%'}}>
        <h3 style={{color: 'white'}}>Password Reset</h3>
        {this.state.error && (
          <div className="cfff fw900">Invalid email or password!</div>
        )}
        {this.state.done &&
          <div className="cfff fw900">If an account exists on file for this email address, a reset email will be sent within the next five minutes. </div>
        }
        {!this.state.done && (
        <span>
        <div style={{color: 'white'}}>Please enter your email address and a link to reset your password will be sent to the email address on file.</div>
        <input
          type="email"
          value={this.state.email}
          onChange={(e) =>
            this.setState({ email: e.target.value, error: false })
          }
          className="loginFieldInput"
          placeholder="Email Address"
        />
        {this.state.loading && <Loading />}
        {!this.state.loading && <button style={{marginTop: '10px', color: 'white', display: 'block', borderRadius: '5px', padding: '20px', border: '0', background: '#4284f4', fontWeight: 'bold'}}
          onClick={this.submitResetRequest}>
          Reset
        </button>}
        </span>
        )}
        </div>
      </div>
    )
  }
}

export default withRouter(ResetPassword)
