import React from "react"
import { Text, Button } from "components"
import AdjustHours from "./AdjustHours"
import HoursBarChart from './HoursBarChart'

const Helpers = require('./helpers')

class PickupHours extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      hours:Helpers.shiftHours(this.props.hours)
    }
  }
  
  componentDidUpdate(prevProps, prevState){
    if(JSON.stringify(prevProps.hours) !== JSON.stringify(this.props.hours)){
      this.setState({
        hours:Helpers.shiftHours(this.props.hours)
      })
    }
  }

  updateHours = (hours) =>{
    this.setState({hours: hours})
  }

  render() {
    return (
      <>
        <Text size="header" weight="black" color="pink">
          Pickup Hours
        </Text>
        <HoursBarChart 
          hours={Helpers.shitDayOfWeekToZeroStart(this.state.hours)}
          labels={Helpers.DAYS}
        />
        <div className="mt-10 left">
          <Text weight="black" size="xl">
            Adjust Pickup Hours
          </Text>
          <AdjustHours
            hours={this.state.hours} 
            updateHours={this.updateHours}
            saveHours={this.props.saveHours}
            schedule='Pickup'
            getSchedules={this.props.getSchedules}
            handleToggle={this.handleToggle}
          />
        </div>
      </>
    )
  }
}
export default PickupHours
