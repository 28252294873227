import React, { Component } from 'react';
import './Integrations.css';
// const API = require('../api')

class Integration extends Component {

  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      username: this.props.integration['username'],
      password: this.props.integration['password']
    }
  }

  renderContent(){
    if (this.state.edit){
      return(
        <div className='IntegrationContent left mh16'>
          <input className='fs16' style={{minWidth: '240px'}} type='email' value={this.state.username} onChange={(e)=>this.setState({username:e.target.value})}/>
          <input className='fs16' style={{minWidth: '240px'}} type='text' value={this.state.password} onChange={(e)=>this.setState({password:e.target.value})}/>
        </div>
      )
    } else {
      return(
        <div className='IntegrationContent left mh16'>
          <div className='fs16 fw700 c222'>{this.state.username}</div>
        </div>
      )
    }
  }

  render(){
    if (this.props.integration){
      return (
        <div className="Integration">
          <img src={`/${this.props.integration.remote_site}.png`} className='IntegrationImg' alt='account'/>
          <h2 className='mh16'>{this.props.integration.remote_site}</h2>
          {this.renderContent()}
          {/* <button className='IntegrationButton' onClick={()=>this.setState({edit:!this.state.edit})}>{this.state.edit ? 'SAVE' : 'EDIT'}</button> */}
        </div>
      )
    } else return null
  }
}

export default Integration;
