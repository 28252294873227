import React, { Component } from "react"
import Loading from "../Loading/Loading"
import { withRouter } from "react-router-dom"
import { GoogleLogin } from "react-google-login"
import "./Login.css"
const API = require("../api")

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      loading: false,
      error: false,
    }

    this.resetPasswordFlow = this.resetPasswordFlow.bind(this)
  }

  // user elected to reset password, so send them to that flow
  resetPasswordFlow() {
    this.props.history.push("/login/reset")
  }

  login() {
    this.setState({ loading: true })
    API.call(
      "login",
      (res) => this.handleLoginSuccess(res),
      (err) => this.handleLoginFailure(err),
      null,
      {
        username: this.state.email,
        password: this.state.password,
      }
    )
  }

  handleLoginSuccess(res) {
    this.setState({ loading: false })
    this.props.loginUser(
      res.api_key,
      res.user,
      res.rest,
      res.brand,
      res.rests,
      res.integrations
    )
    this.props.history.push("/")
  }

  handleLoginFailure(err) {
    this.setState({ loading: false, error: true })
    console.log(err)
  }

  renderLoginButton() {
    if (this.state.loading) {
      return <Loading />
    } else {
      return (
        <button
          classNames="button login-button"
          onClick={() => this.login()}
          style={
            !this.state.email || !this.state.password
              ? { display: "none" }
              : null
          }
        >
          LOGIN
        </button>
      )
    }
  }

  handleGoogleSuccess(res) {
    API.call(
      "google_login",
      (res) => this.handleLoginSuccess(res),
      (err) => this.handleLoginFailure(err),
      null,
      { access_token: res.accessToken }
    )
  }

  handleGoogleFailure(err) {
    console.log(err)
  }

  render() {
    return (
      <div className="Login">
        <GoogleLogin
          clientId={process.env.REACT_APP_MEMBER_GOOGLE_CLIENT_ID}
          buttonText="Login with Google"
          className="LoginGoogle login-button"
          onSuccess={(res) => this.handleGoogleSuccess(res)}
          onFailure={(err) => this.handleGoogleFailure(err)}
          cookiePolicy={"single_host_origin"}
        />
        <div className="cfff fw900 pv16">- or -</div>
        {this.state.error && (
          <div className="cfff fw900">Invalid email or password!</div>
        )}
        <input
          type="email"
          value={this.state.email}
          onChange={(e) =>
            this.setState({ email: e.target.value, error: false })
          }
          className="loginFieldInput"
          placeholder="Email..."
        />
        <input
          type="password"
          value={this.state.password}
          onChange={(e) =>
            this.setState({ password: e.target.value, error: false })
          }
          className="loginFieldInput"
          placeholder="Password..."
        />
        {this.renderLoginButton()}

        <div style={{marginTop: 15}}>
          <a href='#' 
            onClick={this.resetPasswordFlow}
            style={{color: 'black'}}>Forgot Password?</a>
        </div>
      </div>
    )
  }
}

export default withRouter(Login)
