import React from "react"
import classnames from "classnames"
import { withMobile } from "hoc"
import { ReactComponent as Delete } from "assets/icons/delete.svg"
import { Text, Button, ButtonWrapper } from "components"
import Loading from "../../Loading/Loading"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Helpers = require("./helpers")
const API = require("../../api.js")
const formatDate = require("../../Helpers/formatDate")

class SpecialHours extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      description: "",
      endDate: null, 
      endTime: "",
      startDate: null, 
      startTime: "",
    }
    this.loadSpecialHours()
    this.save = this.save.bind(this)
  }

  async loadSpecialHours() {
    let rest = await this.props.loadRest()
    this.rest = rest
    API.call(
      "special-hours",
      (resp) => {
        this.setState({
          pastClosures: resp.past_closures,
          upcomingClosures: resp.upcoming_closures,
          loaded: true,
        })
      },
      (err) => {
        alert("Error getting special hours")
      },
      null,
      { rest_id: rest.restaurant_id }
    )
  }


  convertDateToUTC(date){
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
  }

  
  async save() {
    this.setState({loadingSave:true})
    let rest = await this.props.loadRest()
    let params = {
      description: this.state.description,
      start_date: formatDate.yyyy_mm_dd(this.state.startDate),
      start_time: formatDate.timeString(this.convertDateToUTC(this.state.startTime)),
      end_date: formatDate.yyyy_mm_dd(this.state.endDate),
      end_time: formatDate.timeString(this.convertDateToUTC(this.state.endTime)),
      rest_id: rest.restaurant_id,
    }
    console.log("params", params)
    API.call(
      "special-hours-new",
      (resp) => {
        if(resp.success){
          this.loadSpecialHours()
          this.setState({
            endDate: "",
            endTime: "",
            startDate: "",
            startTime: "",
            description: "",
            loadingSave:false
          })
        } else {
          alert(resp.message)
          this.setState({loadingSave:false})
        }
      },
      (err) => {
        alert("Error getting special hours")
      },
      null,
      params
    )
  }

  // im getting these dates to strings..... so we dont pass around
  // timezones
  deleteClosure(closure) {
    let start = formatDate.formatDateTime(closure.start)
    let end = formatDate.formatDateTime(closure.end)
    API.call(
      "special-hours-delete",
      (resp) => {
        this.loadSpecialHours()
      },
      (err) => {
        alert("Error deleting special hours")
      },
      null,
      { rest_id: this.rest.restaurant_id, start: start, end: end }
    )
  }

  render() {
    const { description, endTime, endDate, startTime, startDate } = this.state
    const isDisabled =
      !endTime || !endDate || !startTime || !startDate
    return (
      <>
        <Text size="header" weight="black" color="pink">
          Special Hours
        </Text>

        <Text size="xl" weight="black" color="black" className="mt-8">
          Create a New Closure
        </Text>
        <div className="mt-4 w100 flex-col">
          <div style={{ textAlign: "left" }} className="flex-row mq__flex-col">
            <div className="hours-input__special-container">
              Closure Description
              <br />
              <input
                className="no-ryan-input input--b--l-grey w100 mt-2 hours-input__special"
                value={this.state.description}
                placeholder="Ex. Holiday Hours"
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </div>
            <div className="flex-col flex-basis-50 ">
              <div
                className={classnames("special-hours__row flex-row", {
                  "flex-col": this.props.isMobile,
                })}
              >
                <div className="flex-col flex-grow-2 mr-4">
                  Start Date
                  <br />
                  <DatePicker
                    placeholderText="Click to select date"
                    selected={this.state.startDate}
                    withPortal={window.innerWidth < 600}
                    onChange={(e)=>{
                      this.setState({startDate:e})
                      if(!this.state.startTime){
                        let d = new Date()
                        d.setHours(0,0)
                        console.log("start time", d)
                        this.setState({startTime:d})
                      }
                    }}
                  />

                </div>
                <div className="flex-col flex-grow-1">
                  Start Time
                  <br />
                  <DatePicker
                    placeHolder='Click to select time'
                    selected={this.state.startTime}
                    onChange={date => this.setState({startTime:date})} 
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="h:mm aa"
                    withPortal={window.innerWidth < 600}
                  />
 
                </div>
              </div>
              <div
                className={classnames("special-hours__row flex-row", {
                  "flex-col": this.props.isMobile,
                  "mt-6": !this.props.isMobile,
                })}
              >

                <div className="flex-col flex-grow-2 mr-4">
                  End Date
                  <br />

                  <DatePicker
                    placeholderText="Click to select date"
                    selected={this.state.endDate}
                    withPortal={window.innerWidth < 600}
                    minDate={this.state.startDate}
                    onChange={(e)=>{
                      this.setState({endDate:e})
                      if(!this.state.endTime){
                        let d = new Date()
                        d.setHours(23,59)
                        this.setState({endTime:d})
                      }
                    }}
                  />
   
                </div>
                <div className="flex-col flex-grow-1">
                  End Time
                  <br />
                  <DatePicker
                    placeHolder='Click to select time'
                    selected={this.state.endTime}
                    onChange={date => this.setState({endTime:date})} 
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="h:mm aa"
                    withPortal={window.innerWidth < 600}
                    injectTimes={[new Date().setHours(23, 59)]}
                  />
                </div>
              </div>
            </div>
            <br />
          </div>
          <Button
            onClick={this.save}
            color="pink"
            type="primary"
            disabled={isDisabled || this.state.loadingSave}
            className="ml-auto mt-8 ph-20"
          >
            {this.state.loadingSave ? <Loading /> : <>Save</>}
          </Button>
        </div>
        <br />
        <br />
        <br />

        {this.state.loaded ? (
          <>
            <Text color="black" size="xl" weight="black">
              Upcoming Closures
            </Text>
            <table>
              <thead>
                <tr>
                  {!this.props.isMobile ? (<th>Description</th>) : null}
                  <th>Start</th>
                  <th>End</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.state.upcomingClosures.map((c) => (
                  <ClosuresRow
                    closure={c}
                    isMobile={this.props.isMobile}
                    trash={() => {
                      this.deleteClosure(c)
                    }}
                    isUpcoming
                  />
                ))}
              </tbody>
            </table>
            <br />
            <br />
            <br />
            <Text color="black" size="xl" weight="black">
              Past Closures
            </Text>
            <table>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Start</th>
                  <th>End</th>
                </tr>
              </thead>
              <tbody>
                {this.state.pastClosures.map((c) => (
                  <tr>
                    <td>{c.description}</td>
                    <td>{formatDate.formatDateTime(c.start)}</td>
                    <td>{formatDate.formatDateTime(c.end)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <Loading />
        )}
      </>
    )
  }
}

const ClosuresRow = ({ closure, trash, isMobile }) => {
  return (
    <tr>
      {!isMobile ? (<td>{closure.description}</td>) : null}
      <td>{formatDate.formatDateTime(closure.start)}</td>
      <td>{formatDate.formatDateTime(closure.end)}</td>
      <td>
        <ButtonWrapper onClick={trash}>
          <Delete />
        </ButtonWrapper>
      </td>
    </tr>
  )
}

export default withMobile(SpecialHours)
