import React from "react"
import { withToasts } from "toasts"
import { Text, Tabs } from "components"
import QRCodeList from "./QRCodeList"
import QRCodeForm from "./QRCodeForm"

const API = require("../../api.js")

class QR extends React.Component {
  constructor(props) {
    super(props)
    this.state = {brand: props.brand,
      initialTab: 'QR Codes',
      forceTab: null,
      qr_codes: null}
    this.tabMap = this.tabMap.bind(this)
    this.appendNewQR = this.appendNewQR.bind(this)
  } 
  
  tabMap = () => ({
    'QR Codes': (
      <QRCodeList qr_codes={this.state.qr_codes} 
        brand={this.state.brand} />
    ), 
    'Create New': (
      <QRCodeForm brand={this.state.brand} 
        appendNewQR={this.appendNewQR}/>
    )
  })

  appendNewQR(qr) {
    let qr_codes = this.state.qr_codes
    let new_list = [qr]
    for (let i in qr_codes) {
      let old_qr = qr_codes[i]
      new_list.push(old_qr)
    }
    this.setState({qr_codes: new_list,
      forceTab: 'QR Codes'})
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.brand && this.props != prevProps) {
      console.log(this.props.brand.qr_codes)
      this.setState({brand: this.props.brand,
        initialTab: this.props.brand.qr_codes.length > 0 ? 'QR Codes' : 'Create New',
        qr_codes: this.props.brand.qr_codes})
    }
  }

  render() {
    console.log(this.state.initialTab)
    return (this.state.qr_codes && 
      <Tabs
        tabMap={this.tabMap()}
        forceTab={this.state.forceTab}
        initialTab={this.state.initialTab}
        childClass="flex-col flex-align-start p-3"
      />
    )
  }
}


export default withToasts(QR)
