import { call } from "api"

export const fetchModifiers = ({ cb, subdomain }) => {
  return new Promise((resolve, reject) => {
    call(
      "menu-modifiers",
      (data) => resolve({ menu: data }),
      () => reject(),
      {
        subdomain: subdomain,
      },
      (err) => {
        reject(err)
      },
      {
        subdomain: subdomain,
      }
    )
  })
}

export const fetchItems = ({ cb, subdomain }) => {
  return new Promise((resolve, reject) => {
    call(
      "menu-modifiers",
      (data) => {
        // resolve({
        call(
          "blackout",
          (blackoutData) =>
            resolve({ menu: data, blackouts: blackoutData.items }),
          () => reject(),
          {
            subdomain: subdomain,
          }
        )
        // })
      },
      () => {
        reject()
      },
      {
        subdomain: subdomain,
      }
    )
  })
}

export const activateBulk = ({ items }) => {
  const mappedItems = []
  items.forEach((item) => mappedItems.push(item))
  return new Promise((resolve, reject) => {
    call(
      "activate-bulk",
      (data) => resolve(data),
      () => reject(),
      null,
      { items: mappedItems }
    )
  })
}

export const activate = ({ cb, id }) => {
  return new Promise((resolve, reject) => {
    call(
      "reactivate-item",
      (data) => resolve(data),
      () => reject(),
      null,
      {
        id: id,
      }
    )
  })
}

export const blackout = ({ items, date, time }) => {
  const mappedItems = []
  items.forEach((item) => mappedItems.push(item))
  return new Promise((resolve, reject) => {
    call(
      "blackout-item",
      (data) => resolve(data),
      () => reject(),
      null,
      { items: mappedItems, date, time }
    )
  })
}
