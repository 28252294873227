import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Text, ButtonWrapper } from "components"
import { withMobile } from "hoc"
import LoadingCard from "Loading/Loading"

import "./tabs.scss"

/*
Tabs:
Takes in a map of tab names and their respectively rendered Components
The keys are rendered as the tab names

you can also optionally pass in a classname for the element that contians the rendered child

Example:

{
    Today: (
      <TodayHours
        loadRest={this.props.loadRest}
        hours={this.state.hours}
        loadHours={this.loadHours}
      />
    ),
    "Pickup Hours": (
      <PickupHours
        hours={this.state.hours.pickup}
        getSchedules={this.getSchedules}
        saveHours={this.saveHours}
      />
    ),

    "Delivery Hours": (
      <DeliveryHours
        hours={this.state.hours.delivery}
        getSchedules={this.getSchedules}
        saveHours={this.saveHours}
      />
    ),
    "Special Hours": <SpecialHours loadRest={this.props.loadRest} />,
}

*/

const Tabs = withMobile((props, ...rest) => {
  const { initialTab, forceTab, tabMap, isMobile, childClass, waitFor, setTabOn } = props
  
  // handle the currently set and active tab
  const [activeTab, setActiveTab] = useState(() => { 
    return initialTab ? initialTab : Object.keys(tabMap)[0]})

  useEffect(() => {
    console.log('use effect forcetab')
    console.log(forceTab)
    if (forceTab) {
      setActiveTab(forceTab)
    }
  }, [forceTab])

  const handleTabChange = (tab) => {
    props.onTabClick(tab)
    setActiveTab(tab)
  }

  const RenderTab = (tab) => {
    if (!activeTab) {
      return null
    }
    const activeComp = tabMap[activeTab]
    if (!activeComp) {
      return null
    }
    if (!waitFor) {
      return <LoadingCard />
    }
    const el = React.cloneElement(activeComp, {
      activeTab: activeTab,
      ...rest,
    })
    return el
  }

  if (setTabOn.condition && activeTab !== setTabOn.tab) {
    setActiveTab(setTabOn.tab)
  }
  return (
    <div className="flex-col">
      <div
        className={classnames("tabs-container flex-row flex-inline mr-auto", {
          "flex-justify-start x-auto": isMobile,
        })}
      >
        {Object.keys(tabMap).map((tab, i) => (
          <ButtonWrapper
            onClick={() => handleTabChange(tab)}
            key={i}
            options={{ withBorder: true }}
            className="tab-button"
          >
            <Text
              color="blue"
              weight="black"
              className={classnames("tab no-wrap", {
                "border-2 tab--active":
                  activeTab === tab && !props.shouldPreventRender,
                "ph-8 pv-4": !isMobile,
                "mobile-tab": isMobile,
              })}
              size="l"
            >
              {tab}
            </Text>
          </ButtonWrapper>
        ))}
      </div>
      <div className={childClass}>
        {!props.shouldPreventRender && RenderTab(activeTab)}
      </div>
    </div>
  )
})

Tabs.defaultProps = {
  waitFor: true,
  shouldPreventRender: false,
  onTabClick: () => {},
  setTabOn: {},
}

Tabs.propTypes = {
  tabMap: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  childClass: PropTypes.string,
  initialTab: PropTypes.string,
  shouldPreventRender: PropTypes.bool,
  onTabClick: PropTypes.func,
}
export default Tabs
