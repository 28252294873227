import React, { Component } from "react"
const API = require("../api")

class NewIntegration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      new_remote_site: null,
      new_username: "",
      new_password: "",
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.new_remote_site !== this.props.new_remote_site) {
      this.setState({ new_remote_site: this.props.new_remote_site })
    }
  }

  addIntegration() {
    API.call(
      "add_integration",
      (res) => this.handleAddIntegrationSuccess(res),
      (err) => this.handleAddIntegrationFailure(err),
      null,
      {
        username: this.state.new_username,
        password: this.state.new_password,
        corporate_account_id: this.props.rest.corporate_account_id,
        remote_site: this.state.new_remote_site,
      }
    )
  }

  handleAddIntegrationSuccess(res) {
    console.log("SUCCESS:", res)
    this.props.refreshData()
    this.setState({ new_remote_site: null })
  }

  handleAddIntegrationFailure(err) {
    console.log("FAILURE:", err)
  }

  render() {
    if (this.state.new_remote_site !== null) {
      if (!this.state.new_remote_site.length) {
        let sites = [
          "9fold",
          "ChowNow",
          "DoorDash",
          "GrubHub",
          "iMenu360",
          "MenuDrive",
        ]
        return (
          <div>
            <div className="pv16 fs21 fw700">
              Select your integration platform:
            </div>
            {sites.map((site) => (
              <div
                key={site}
                className="IntegrationNewImg"
                onClick={() => this.setState({ new_remote_site: site })}
              >
                <img
                  className=" w64px mh8 br4 shadow inline-block pointer"
                  src={`/${site}.png`}
                  alt="remote_site"
                />
                <br />
                {site}
              </div>
            ))}
            <br />
            <button
              className="button IntegrationButton bgred"
              onClick={() => this.setState({ new_remote_site: null })}
            >
              CANCEL
            </button>
          </div>
        )
      } else {
        return (
          <div>
            <div className="pv16 fs21 fw700">
              New {this.state.new_remote_site} Integration:
            </div>
            <div className="Integration">
              <img
                src={`/${this.state.new_remote_site}.png`}
                className="IntegrationImg"
                alt="account"
              />
              <div className="IntegrationContent left mh16">
                <input
                  className="fs16"
                  style={{ minWidth: "240px" }}
                  type="email"
                  placeholder="username..."
                  value={this.state.new_username}
                  onChange={(e) =>
                    this.setState({ new_username: e.target.value })
                  }
                />
                <input
                  className="fs16"
                  style={{ minWidth: "240px" }}
                  type="password"
                  placeholder="password..."
                  value={this.state.new_password}
                  onChange={(e) =>
                    this.setState({ new_password: e.target.value })
                  }
                />
              </div>
              <button
                className="button IntegrationButton"
                onClick={() => this.addIntegration()}
              >
                SAVE
              </button>
            </div>
            <button
              className="button IntegrationButton bgred"
              onClick={() => this.setState({ new_remote_site: "" })}
            >
              BACK
            </button>
          </div>
        )
      }
    } else {
      return (
        <button
          className="button bggreen"
          onClick={() => this.setState({ new_remote_site: "" })}
        >
          + Add New Integration
        </button>
      )
    }
  }
}

export default NewIntegration
