import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { Text, Button, ButtonWrapper } from "components"
import Footer from "./Footer"
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg"
import "./Sidebar.css"
import Modal from 'react-modal'
const API = require("../api")

//const PAGES = ["feed", "integrations", "finance", "menus", "profile"]
const PAGES = {finance: 'Finance',
  menus: 'Menus',
  hours: 'Hours',
  settings: 'Settings',
  profile: 'Profile',
  liveorders:'Live Orders'
}
const PAGES_NO_ORDERING = {feed: 'Feed',
  integrations: 'Integrations'}

class Sidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      switch_restaurant: false,
      expanded: false,
      selected_rest: this.props.rest,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.rest !== prevProps.rest) {
      this.setState({ selected_rest: this.props.rest })
    }
  }

  logout() {
    localStorage.clear()
    this.props.history.push("/login")
  }

  switchRestaurants() {
    this.toggleExpanded()
    API.call(
      "user_restaurants",
      (res) => this.handleUserRestaurantsSuccess(res),
      (err) => this.handleUserRestaurantsFailure(err),
      null,
      null
    )
  }

  handleUserRestaurantsSuccess(res) {
    this.setState({ rests: res.restaurants, switch_restaurant: true })
  }

  handleUserRestaurantsFailure(err) {
    console.log(err)
  }

  selectRestaurant() {
    API.call(
      "change_restaurant",
      (res) => this.handleChangeRestaurantSuccess(res),
      (err) => this.handleChangeRestaurantFailure(err),
      null,
      {
        rest_id: this.state.selected_rest.restaurant_id,
        corporate_account_id: this.state.selected_rest.corporate_account_id,
      }
    )
  }

  handleChangeRestaurantSuccess(res) {
    localStorage.setItem("rest", JSON.stringify(res.rest))
    window.location.reload()
  }

  handleChangeRestaurantFailure(err) {
    console.error(err)
  }

  toggleExpanded = () => {
    this.setState((prevState) => {
      return { expanded: !prevState.expanded }
    })
  }

  renderSwitchRestaurant() {
    if (this.state.switch_restaurant) {
      return (
        <Modal
          isOpen={true}
          style={{ padding: "8px" }}
          onRequestClose={()=>this.setState({switch_restaurant:false})}
          style={{content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            maxWidth:'80vw',
            padding:'0px',
            zIndex:9999,
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          },
          overlay:{
            background: 'rgba(0,0,0,.5)'
          }}}
        >
          <div className='switch-modal'>
            <h2>Switch Restaurants</h2>
            <div className="center w100 inline-block">
              {this.state.rests.map((rest, i) => {
                if (
                  this.state.selected_rest.restaurant_id === rest.restaurant_id
                ) {
                  return (
                    <div
                      key={i}
                      style={{ transition: "all .2s" }}
                      className="w80 pv8 ph16  mh4 br4 left bgblue cfff ellipsis center inline-block pointer"
                      onClick={(e) => this.setState({ selected_rest: rest })}
                    >
                      {rest.name} 
                      {rest.secondary_name ? ` - ${rest.secondary_name}` : null}
                      {rest.address1 && ` (${rest.address1})`}
                    </div>
                  )
                } else {
                  return (
                    
                    <div
                      key={i}
                      style={{ transition: "all .2s" }}
                      className="w80 pv8 ph8  mh4 br4 left bgfff c222 ellipsis center inline-block pointer"
                      onClick={(e) => this.setState({ selected_rest: rest })}
                    >
                      {rest.name}
                      {rest.secondary_name ? ` - ${rest.secondary_name}` : null}
                      {rest.address1 && ` (${rest.address1})`}
                    </div>
                 )
                }
              })}
              <br />
              <button onClick={() => this.selectRestaurant()} className="button">
                SAVE
              </button>
              <br />
              <br />
            </div>
          </div>
        </Modal>
      )
    }
  }

  renderMobile() {
    return this.props.rest ? (
      <div className="SidebarMobile">
        <div className="SidebarContainerMobile">
          <img
            src="/menu.png"
            className="SidebarMenu"
            onClick={this.toggleExpanded}
            alt="menu"
          />
          <div className="sidebar-mobile__center">
            <div className="SidebarHNGRMobile">
              <Text weight="black" color="pink">
                Hngr.
              </Text>
            </div>
          </div>
        </div>
        {this.renderExpansion()}
        {this.renderSwitchRestaurant()}
      </div>
    ) : null
  }

  renderExpansion() {
    if (this.state.expanded) {
      return (
        <div className="pv16 SidebarExpansion">
          <Text color="white" weight="bold">
            {this.props.rest.name}
          </Text>
          {this.props.user && this.props.user.has_multiple_rests && (
            <Button
              type="primary-inverted"
              color="white"
              className="mv-4"
              fontSize="m"
              onClick={() => this.switchRestaurants()}
            >
              Switch Restaurants
            </Button>
          )}
          {this.renderPagesDesktop()}
          <div className="w100 mt-auto">
            {/*
            <Link to="/support" className="block pv-2 m-2 ph-2">
              <Text color="white" weight="black">
                Hngr Support
              </Text>
            </Link>*/}
            <ButtonWrapper
              onClick={() => this.logout()}
              className="w100 flex-row pv-2 m-2 ph-2"
            >
              <Text weight="black" color="white">
                Logout
              </Text>
              <LogoutIcon className="ml-2" />
            </ButtonWrapper>
          </div>
        </div>
      )
    }
  }

  renderDesktop() {
    return this.props.rest ? (
      <aside className="Sidebar">
        <div className="SidebarContent">
          <Text weight="black" color="pink" size="header">
            Hngr.
          </Text>

          {this.props.rest.logo_url && (
            <img
              src={this.props.rest.logo_url}
              className="SidebarLogo"
              alt="restaurant logo"
            />
          )}
          <div className="fs21 fw700 cfff">
            {this.props.rest.name}
            {this.props.rest.secondary_name ? 
              <div>{this.props.rest.secondary_name}</div>
              : null
            }
          </div>
          {this.props.rest.address1 && (
            <div className="fs16 fw300 cfff">{this.props.rest.address1}</div>
          )}
          {this.props.rest.city && (
            <div className="fs16 fw300 cfff">
              {this.props.rest.city}, {this.props.rest.state}{" "}
              {this.props.rest.zipcode}
            </div>
          )}
          {this.props.user && this.props.user.has_multiple_rests && (
            <Button
              type="primary-inverted"
              color="white"
              fontSize="m"
              className="mv-6"
              onClick={() => this.switchRestaurants()}
            >
              Switch Restaurants
            </Button>
          )}
          {this.renderSwitchRestaurant()}
          {this.renderPagesDesktop()}
          <div className="w100 mt-auto p-4">
            <Link to="/support" className="block mb-4">
              <Text color="white" weight="black">
                Hngr Support
              </Text>
            </Link>
            <ButtonWrapper
              onClick={() => this.logout()}
              className="w100 flex-row"
            >
              <Text weight="black" color="white">
                Logout
              </Text>
              <LogoutIcon className="ml-2" />
            </ButtonWrapper>
          </div>
        </div>
      </aside>
    ) : null
  }

  renderPagesDesktop() {
    let active = this.props.page
    const pages = this.props.rest.online_ordering_enabled
      ? PAGES
      : PAGES_NO_ORDERING
    
    // determine whther to show QR component
    if (this.props.brand && this.props.brand.qr_codes !== null ) {
      const qrComponent = ['QR Codes', 'qr']
      if (!(qrComponent in pages)) {
        pages['qr'] = 'QR Codes'
      }
    }

    if(this.props.mobile){
      return (
        <div className="flex-col w100">
          {Object.entries(pages).map(([i, page]) => {
            return (
              <Link
                key={i}
                to={`/${i}`}
                onClick={this.toggleExpanded}
                className={
                  page === active
                    ? " SidebarPageMobile SidebarPageActive  fw900 cfff"
                    : "SidebarPageMobile  fw700 cfff"
                }
              >
                <Text size="xl" color="white" weight="black">
                  {page}
                </Text>
              </Link>
            )
          })}
          <a
            target='_blank'
            className="SidebarPage  fw700 cfff"
            href={`https://${this.props.rest.subdomain}.hngr.co`}
          >
            <Text size="xl" color="white" weight="black">
              {this.props.rest.subdomain}.hngr.co 
            </Text>
          </a>
        </div>
      )
    } else {
      return (
        <div className="flex-col w100">
          {Object.entries(pages).map(([i, page]) => {
            return (
              <Link
                key={i}
                to={`/${i}`}
                onClick={this.toggleExpanded}
                className={
                  page === active
                    ? " SidebarPage SidebarPageActive  fw900 cfff"
                    : "SidebarPage  fw700 cfff"
                }
              >
                <Text color="white" weight="black">
                  {page}
                </Text>
              </Link>
            )
          })}
          <a
            target='_blank'
            className="SidebarPage  fw700 cfff"
            href={`https://${this.props.rest.subdomain}.hngr.co`}
          >
            <Text color="white" weight="black">
              {this.props.rest.subdomain}.hngr.co 
            </Text>
          </a>
        </div>
      )
    }
  }

  render() {
    return this.props.mobile ? this.renderMobile() : this.renderDesktop()
  }
}

export default withRouter(Sidebar)
