import React from "react"
import { Text } from "components"
import BrandColor from "./BrandColor/BrandColor"
import Logos from "./Logos/Logos"
import BrandPreview from "./BrandPreview/BrandPreview"
class BrandPage extends React.Component {
  render() {
    return (
      <div>
        <Text color="pink" size="header" weight="black">
          Brand Information
        </Text>
        <div className="mt-8">
          <BrandPreview rest={this.props.rest} />
          <div className="mt-4">
            <BrandColor
              rest={this.props.rest}
              refreshData={this.props.refreshData}
            />
          </div>
          <div className="mt-4">
            <Logos
              rest={this.props.rest}
              refreshData={this.props.refreshData}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default BrandPage
