import React, { useState, useEffect } from "react"
import Modal from "Modal/Modal"
import { Button } from "components"

const API = require("../../api.js")

const EditUserModal = (props) => {
  const { userModalUser, closeModal, afterSave, restaurant_id } = props

  const [userState, setUserState] = useState(() => {
    return {
      name: userModalUser.name,
      phone: userModalUser.phone,
      sms_alert: userModalUser.sms_alert,
      user_id: userModalUser.id,
    }
  })

  const handleChange = (field, value) => {
    setUserState({
      ...userState,
      [field]: value,
    })
  }

  const saveUser = () => {
    // validate that the phone is 10 digits 
    let phone = userState.phone
    if (phone){
      phone = phone.replace(/\D/g,''); 
      if (phone.length !== 10){
        alert('Please enter a valid phone number')
        return
      } else{
        userState.phone = phone
      }
    }
    
    API.call(
      "save-user",
      (resp) => {
        afterSave()
      },
      (err) => {
        alert("Error loading users")
      },
      null,
      {
        rest_id: restaurant_id,
        ...userState,
      }
    )
  }

  return (
    <Modal close={closeModal} className="p-4">
      <h3>{userModalUser.name}</h3>
      <div>
        <table style={{ padding: "15px" }}>
          <tbody>
            <tr>
              <td>Phone</td>
              <td>
                <input
                  value={userState.phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Receive SMS</td>
              <td>
                <input
                  type="checkbox"
                  checked={userState.sms_alert}
                  onChange={(e) => {
                    handleChange("sms_alert", e.target.checked)
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button
        onClick={saveUser}
        color="pink"
        type="primary"
        className="ml-auto mt-8 ph-20"
      >
        Save
      </Button>
    </Modal>
  )
}
export default EditUserModal
