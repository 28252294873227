import React from "react"
import Loading from "../../Loading/Loading";

const API = require("../../api.js")

class LiveOrders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {loaded:false}
    this.getApiKey()
    window.G = this
  }

  loginTablet(){
    if(window.location.href.indexOf('hngr')  === -1) return

    if(window.frames[0].frameElement.contentWindow.SIDEBAR){
      window.frames[0].frameElement.contentWindow.SIDEBAR.logout(true)
      setTimeout(()=>{
        window.frames[0].frameElement.contentWindow.LOGIN.state.apiKeyInput = this.state.apiKey
        window.frames[0].frameElement.contentWindow.LOGIN.verifyApiKey()
        setTimeout(()=>this.setState({loaded:true}), 1500)
      }, 500)
    } else{
      setTimeout(()=>{
        window.frames[0].frameElement.contentWindow.LOGIN.state.apiKeyInput = this.state.apiKey
        window.frames[0].frameElement.contentWindow.LOGIN.verifyApiKey()
        setTimeout(()=>this.setState({loaded:true}), 1000)
      }, 500)
    }
  }

  async getApiKey(){
    let rest = await this.props.loadRest()
    this.rest = rest
    API.call(
      "tablet-api-key",
      (resp) => {
        this.setState({
          apiKey:resp.api_key
        }, ()=>setTimeout(()=>this.loginTablet(), 2000))
      },  
      (err) => {
        alert("Error getting special hours")
      },  
      null,
      { rest_id: rest.restaurant_id }
    )   
  }

  render() {
    if(window.innerWidth < 1000){
      return (
        <div style={{fontSize:'35px'}}>
          This page is only viewable from a computer
        </div>
      )
    }

    return (
      <div>
        {!this.state.loaded && (
          <div>
            <Loading/>
          </div>
        )}

        <div className='odot-outer-container'>
          <div 
            className='odot-container'
            style={!this.state.loaded ? {opacity:0.0} : {}} 
          >
            <iframe 
              className='odot'
              src='https://o.hngr.co'
            />
          </div>
        </div>
      </div>
    )
  }
}


export default LiveOrders 
