import React from "react"
import { debounce } from "Helpers"

const DEFAULT_MENU_STATE = { items: [], modifiers: [] }

//We only need an array of menu item ids because when rendering, we can just look the item up
const filterMenuDeep = (menu, query) => {
  const lowerQuery = query.toLowerCase()
  return Object.keys(menu).reduce((acc, key) => {
    // console.log(menu[key].items)
    const items = menu[key].items.filter((item) => {
      const lowerName = item.name.toLowerCase()
      const lowerDesc = item.description ? item.description.toLowerCase() : ""
      return (
        lowerName.indexOf(lowerQuery) !== -1 ||
        lowerDesc.indexOf(lowerQuery) !== -1
      )
    })
    acc.push(...items.map((item) => item.menu_item_id))
    return acc
  }, [])
}

// for modifier ids
const filterMenuShallow = (menu, lookupTable, query) => {
  const lowerQuery = query.toLowerCase()
  return Object.keys(menu).reduce((acc, key) => {
    const items = menu[key].filter((id) => {
      //need to lookup name
      const item = lookupTable[id]

      const lowerName = item.name.toLowerCase()
      const lowerDesc = item.description ? item.description.toLowerCase() : ""
      return (
        lowerName.indexOf(lowerQuery) !== -1 ||
        lowerDesc.indexOf(lowerQuery) !== -1
      )
    })
    acc.push(...items)
    return acc
  }, [])
}

//for blacked out ids
const filterMenuById = (ids, lookupTable, query) => {
  const lowerQuery = query.toLowerCase()
  return ids.filter((id) => {
    const item = lookupTable[id]
    if (!item) return false

    const lowerName = item.name.toLowerCase()
    const lowerDesc = item.description ? item.description.toLowerCase() : ""
    return (
      lowerName.indexOf(lowerQuery) !== -1 ||
      lowerDesc.indexOf(lowerQuery) !== -1
    )
  })
}

//This takes in the modifiers,
const debouncedSearch = debounce((props, query, lookupTable, cb) => {
  const { modifiers, items, blackedOutModifiers, blackedOutItems } = props

  let newAvailableItems = filterMenuDeep(items, query)
  let newAvailableModifiers = filterMenuShallow(modifiers, lookupTable, query)
  let newBlackedOutItems = filterMenuById(blackedOutItems, lookupTable, query)
  let newBlackedOutModifiers = filterMenuById(
    blackedOutModifiers,
    lookupTable,
    query
  )

  const newMenu = {
    items: newAvailableItems,
    modifiers: newAvailableModifiers,
    blackedOutItems: newBlackedOutItems,
    blackedOutModifiers: newBlackedOutModifiers,
  }
  cb(newMenu)
}, 500)

class MenuItemsSearch extends React.Component {
  state = {
    searchValue: "",
  }

  componentDidUpdate(prevProps) {
    //clear out the search when someone clicks on a tab after searching
    if (
      prevProps.isSearchActive !== this.props.isSearchActive &&
      !this.props.isSearchActive
    ) {
      this.setState({ searchValue: "" })
    }
  }

  search = async (e) => {
    this.setState({ searchValue: e.target.value })
    if (!e.target.value) {
      // If no seach query, reset menu
      this.props.setSearchMenu(null)
      return
    }
    const cb = (menu) => this.props.setSearchMenu(menu)
    debouncedSearch(this.props, e.target.value, this.props.itemLookupTable, cb)
  }

  render() {
    return (
      <div className='search-wrapper'>
        <div className='search-wrapper-inner'>
          <input
            className="no-ryan-input input--b-grey ph-2 pv-4 mq__m-0 mq__mt-4 search-input"
            value={this.state.searchValue}
            placeholder="Search for menu items or modifiers..."
            onChange={this.search}
          />
          <img className='search-icon' src='/search.png'/>
        </div>
      </div>
    )
  }
}

export default MenuItemsSearch
